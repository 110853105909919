import { Button } from "antd"

import { AppContext } from "components/AppContext"
import { useSubscriptionContext } from "components/Subscription/SubscriptionProvider"
import { useContext } from "react"
import { useSelector } from "react-redux"
import apiService from "services/apiService"
import { profileState } from "states/slices/profileReducer"

export default function PostCardConnect() {
  const { checkSubscription } = useSubscriptionContext()
  const { userInfo } = useSelector(profileState)
  const { instance, setInstance, item, child } = useContext(AppContext) || {}
  const isMe = userInfo?.username === item?.username

  const onConnect = async () => {
    setInstance({ ...instance, connection_status: "connect" })

    try {
      const body = { target_profile_id: item?.profile_id }
      await apiService.post("/api/network/connection", body)
    } catch (e) {
      checkSubscription(e)
    }
  }

  return (
    (child &&
      !isMe &&
      !instance?.company_profile_id &&
      !instance?.connection_status &&
      !instance?.community_id && (
        <Button type="link" onClick={onConnect}>
          + Follow
        </Button>
      )) ||
    null
  )
}
