import { Skeleton } from "antd"
import PreviewContent from "components/Profile/editModals/PreviewContent"
import { useEffect, useState } from "react"
import apiService from "services/apiService"

export default function PortfolioCard({
  post,
  companyId = false,
  setInstance,
}) {
  const [item, setItem] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    if (loading) {
      return
    }

    try {
      setLoading(true)
      let url = "/api/"
      const prefix = companyId ? "company" : "profile"

      switch (post?.post_category) {
        case "product":
          if (!post?.product?.id) {
            return
          }
          url += `profile/products/${post?.product?.id}`
          break
        case "project":
          if (!post?.project?.id) {
            return
          }
          url += `${prefix}/projects/${post?.project?.id}`
          break
        case "portfolio":
          if (!post?.portfolio?.id) {
            return
          }
          url += `${prefix}/portfolio/${post?.portfolio?.id}`
          break
        default:
          return
      }

      const { data } = await apiService.get(url)
      const instance = data?.data
      if (instance) {
        setItem(instance)
        setInstance({ ...instance })
      }
    } catch (e) {
      console.log(e)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (post?.id) {
      fetchData()
    }
  }, [post?.id])

  return loading ? (
    <Skeleton className="small-skeleton" active paragraph={{ rows: 4 }} />
  ) : (
    item && (
      <PreviewContent className="my-3" data={item} type={post?.post_category} />
    )
  )
}
