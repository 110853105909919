import { AppContext } from "components/AppContext"
import PostCardContent from "components/Feed/Posts/PostCardContent"
import PostCardWrapper from "components/Feed/Posts/PostCardWrapper"
import { useContext, useEffect, useState } from "react"

export default function PostCard({
  item,
  child,
  onFetch,
  previewMode,
  refreshData,
  className = "",
  expandable = true,
  showReference = true,
  hideRemoveBookmark = false,
}) {
  const [instance, setInstance] = useState(null)
  const [reference, setReference] = useState(null)
  const { companyDetails = null, communityDetails = null } =
    useContext(AppContext) || {}

  useEffect(() => {
    if (!item) {
      return
    }
    setInstance(item)
  }, [item])

  return (
    <AppContext.Provider
      value={{
        communityDetails,
        companyDetails,
        setInstance,
        reference,
        instance,
        child,
        item,
      }}
    >
      <PostCardWrapper
        onFetch={onFetch}
        className={className}
        previewMode={previewMode}
        refreshData={refreshData}
        hideRemoveBookmark={hideRemoveBookmark}
      >
        <PostCardContent
          showReference={showReference}
          expandable={expandable}
          setReference={setReference}
        />
      </PostCardWrapper>
    </AppContext.Provider>
  )
}
