import { Button } from "antd"
import { AppContext } from "components/AppContext"
import { AddPostContext } from "components/Feed/AddPost/AddPostContext"
import { useContext } from "react"

export default function AddPostModalContentFormSettings() {
  const { communitySpaces = null, selectedSpace = null } =
    useContext(AppContext) || {}

  const {
    category,
    visibility,
    toggleCategory,
    toggleSpaces,
    toggleVisibility,
  } = useContext(AddPostContext)

  return (
    <>
      {communitySpaces?.length ? (
        <Button
          type="light"
          className="my-1 mr-3 ml-0 bg-white"
          style={{ color: "#5a5a5a" }}
          onClick={() => toggleSpaces(true)}
        >
          <div className="flex items-center">
            {selectedSpace ? (
              <span className="inline-block truncate" style={{ maxWidth: 120 }}>
                {selectedSpace?.name}
              </span>
            ) : (
              "Spaces"
            )}
            <span className="icon-ic_arrow_drop_down_24px f-5 text-primary ml-2 inline-block" />
          </div>
        </Button>
      ) : (
        <Button
          type="light"
          className="my-1 mr-3 ml-0 bg-white"
          style={{ color: "#5a5a5a" }}
          onClick={() => toggleCategory(true)}
        >
          <div className="flex items-center">
            {category ? (
              <span className="inline-block">{category?.title}</span>
            ) : (
              "Post Category"
            )}
            <span className="icon-ic_arrow_drop_down_24px f-5 text-primary ml-2 inline-block" />
          </div>
        </Button>
      )}
      <Button
        type="light"
        className="my-1 mr-0 bg-white"
        style={{ color: "#5a5a5a" }}
        onClick={() => toggleVisibility(true)}
      >
        <div className="flex items-center">
          {visibility ? (
            <>
              <span className={`${visibility?.icon} inline-block mr-2`} />
              <span className="ml-2 inline-block">{visibility?.name}</span>
            </>
          ) : (
            "Post Visibility"
          )}
          <span className="icon-ic_arrow_drop_down_24px f-5 text-primary ml-2 inline-block" />
        </div>
      </Button>
    </>
  )
}
