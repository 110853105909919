import { Image, Row } from "antd"
import dynamic from "next/dynamic"

const VideoPlayer = dynamic(() => import("components/CustomFields/VideoPlayer"))
const ImageGallery = dynamic(
  () => import("components/CustomFields/ImageGallery")
)
const DocumentReader = dynamic(
  () => import("components/CustomFields/DocumentReader")
)

const { PreviewGroup } = Image
const baseUrl = process.env.NEXT_PUBLIC_BASE_URL

export default function PostCardFiles({ files }) {
  const isVideo = files?.some(item =>
    /(.mp4|.mov)$/.test(String(item.url).toLowerCase())
  )
  const isDocument = files?.some(item => item.url.includes(".pdf"))

  if (isVideo) {
    return (
      <div>
        {files.map((item, index) => (
          <VideoPlayer
            key={index}
            options={{
              playbackRate: 1,
              autoplay: false,
              controls: true,
              responsive: true,
              fluid: true,
              sources: [
                {
                  src: `${baseUrl}/storage/file_manager/${item.url}`,
                  type: "video/mp4",
                },
              ],
            }}
          />
        ))}
      </div>
    )
  }

  if (isDocument) {
    return files?.map((item, index) => (
      <DocumentReader
        key={index}
        file={`${baseUrl}/api/files/?url=${item.url}`}
        showFullscreen
      />
    ))
  }

  return (
    files?.length !== 0 && (
      <Row gutter={[8, 8]} className="w-full mx-0 overflow-hidden">
        <PreviewGroup>
          {files?.map((file, index) => (
            <ImageGallery
              getFile
              key={index}
              index={index}
              src={file.url}
              fileLength={files?.length}
            />
          ))}
        </PreviewGroup>
      </Row>
    )
  )
}
