import { Button, Divider, Radio } from "antd"
import {
  AddPostContext,
  Categories,
} from "components/Feed/AddPost/AddPostContext"
import { useContext, useState } from "react"

const PortfolioTypes = [
  { title: "Add Project", value: "project" },
  { title: "Add Product", value: "product" },
]

export default function AddPostCategory() {
  const {
    category,
    setCategory,
    portfolioType,
    setPortfolioType,
    toggleCategory,
    savedPhoto,
    savedVideo,
    savedDocument,
  } = useContext(AddPostContext)

  const categories =
    savedPhoto.length !== 0 ||
    savedVideo.length !== 0 ||
    savedDocument.length !== 0
      ? Categories.filter(item => item.value !== "portfolio")
      : Categories

  const [showPortfolio, togglePortfolio] = useState(false)

  const handleCategoryClick = item => {
    setCategory(item)
    if (item.showMore) {
      togglePortfolio(true)
    }
  }

  const handlePortfolioClick = item => {
    setPortfolioType(item)
  }

  const handleBackClick = () => {
    if (showPortfolio) {
      setCategory(null)
      togglePortfolio(false)
    } else {
      toggleCategory(false)
    }
  }

  const RenderItem = ({ item, onClick, isSelected, name }) => (
    <div
      className={`flex items-center py-3 px-5 cursor-pointer hover-gray transition ${
        isSelected ? "selected-primary" : ""
      }`}
      onClick={onClick}
    >
      <div className="flex flex-col ant-text-grey">
        <strong className="f-16">{item?.title}</strong>
        {item?.description && <span className="f-14">{item?.description}</span>}
      </div>
      <div className="flex-grow" />
      <Radio className="m-0" checked={isSelected} name={name} />
    </div>
  )

  return (
    <>
      <div className="mh-212">
        {showPortfolio
          ? PortfolioTypes.map((item, index) => (
              <RenderItem
                key={index}
                item={item}
                onClick={() => handlePortfolioClick(item)}
                isSelected={item?.value === portfolioType?.value}
                name="PostPortfolioType"
              />
            ))
          : categories.map((item, index) => (
              <RenderItem
                key={index}
                item={item}
                onClick={() => handleCategoryClick(item)}
                isSelected={item?.value === category?.value}
                name="PostCategory"
              />
            ))}
      </div>

      <Divider className="border-gray-300 m-0" />

      <div className="p-4 flex justify-end">
        <Button type="default" className="px-8" onClick={handleBackClick}>
          Back
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          className="px-8"
          onClick={() => toggleCategory(false)}
        >
          Save
        </Button>
      </div>
    </>
  )
}
