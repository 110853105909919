import { Button, Image, Radio, Spin, Upload } from "antd"
import CPTextArea from "components/CP/CPTextArea"
import { useSubscriptionContext } from "components/Subscription/SubscriptionProvider"
import { useEffect, useState } from "react"
import apiService from "services/apiService"

const { Dragger } = Upload

export function ImageCard({
  item,
  onRemove,
  onCaption,
  caption,
  is_cover,
  onCover,
}) {
  return (
    <div className="p-2 lg:w-1/4 sm:w-1/2 w-full">
      <div className="relative">
        <Image
          src={item}
          alt={item?.name}
          preview={false}
          style={{ maxHeight: 250 }}
          className="w-full mb-3 rounded-lg bg-white shadow-lg"
        />

        <Button
          type="light"
          style={{ height: 38, width: 38 }}
          className="roudend-lg shadow bg-white m-2 absolute top-0 right-0"
          icon={<span className="icon-ic_delete_24px f-15" />}
          onClick={() => onRemove(item)}
        />

        <CPTextArea
          counter="true"
          className="mb-2"
          placeholder="Write a Caption"
          value={caption}
          onChange={e => onCaption(e?.target?.value)}
        />

        <div className="flex items-center px-1">
          <Radio checked={is_cover} onClick={onCover}>
            Cover Image
          </Radio>

          <div className="flex-grow" />
          <small>{caption?.length}</small>
        </div>
      </div>
    </div>
  )
}

export default function ImageGalleryWithCaption({
  initialData = null,
  directory,
  onChange,
  error,
}) {
  const { checkSubscription } = useSubscriptionContext()
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!initialData) {
      return
    }
    setImages(initialData.images)
  }, [initialData])

  useEffect(() => {
    if (onChange) {
      onChange({ images })
    }
  }, [images])

  const onCaption = (index, val) => {
    const data = [...(images || [])].map((item, i) =>
      i === index ? { ...item, caption: val } : item
    )
    setImages(data)
  }

  const onCover = index => {
    const data = [...(images || [])].map((item, i) => ({
      ...item,
      is_cover: i === index,
    }))
    setImages(data)
  }

  const onRemove = index => {
    const data = [...(images || [])]
    data.splice(index, 1)
    if (data?.length && data?.is_cover) {
      data[0].is_cover = true
    }
    setImages(data)
  }

  const uploadFile = async ({ file, event }) => {
    if (!file || !event) {
      return
    }

    try {
      setLoading(true)

      const formData = new FormData()
      formData.append("file", file.originFileObj)
      formData.append("directory", directory)
      const res = await apiService.post("/api/files/", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })

      setImages([
        {
          caption: "",
          response: res.data,
          is_cover: images?.length === 0,
          url: URL.createObjectURL(file.originFileObj),
        },
        ...(images || []),
      ])
    } catch (e) {
      checkSubscription(e)
    }

    setLoading(false)
  }

  const Props = {
    name: "file",
    maxCount: 1,
    accept: "image/png,image/jpg,image/jpeg,image/gif",
    showUploadList: false,
  }

  const renderPreview = images?.map((item, index) => (
    <ImageCard
      key={index}
      item={item.url}
      caption={item.caption}
      is_cover={item.is_cover}
      onCover={() => onCover(index)}
      onRemove={() => onRemove(index)}
      onCaption={e => onCaption(index, e)}
    />
  ))

  const renderUpload = (
    <div className="px-2 pb-2 pt-6 sm:w-1/2 w-full mt-auto mx-auto">
      <Dragger {...Props} onChange={uploadFile}>
        <div className="f-15 text-center text-gray-500 py-8 px-8">
          Drag images here or
          <span className="text-primary px-1">browse</span>
          to upload up to 5MB each.
        </div>
      </Dragger>
      <div className="text-center text-gray-500 f-14 mt-1 mb-1">
        .jpg, .gif, or .png images
      </div>
    </div>
  )

  const renderError = (
    <div className="text-sm font-medium text-red-600 mb-6 mt-4 text-center">
      {error}
    </div>
  )

  return (
    <>
      <div className="mb-10 flex items-end justify-start flex-wrap">
        {renderPreview}
        {loading && (
          <div className="lg:w-1/3 sm:w-1/2 w-full flex justify-center">
            <Spin />
          </div>
        )}

        {renderUpload}
      </div>

      {renderError}
    </>
  )
}
