import { AppContext } from "components/AppContext"
import AddAssetModal from "components/Feed/AddPost/AddAssetModal"
import AddPostCategory from "components/Feed/AddPost/AddPostCategory"
import { AddPostContext } from "components/Feed/AddPost/AddPostContext"
import AddPostModalContentForm from "components/Feed/AddPost/AddPostModalContentForm"
import AddPostSpace from "components/Feed/AddPost/AddPostSpace"
import AddPostVisibility from "components/Feed/AddPost/AddPostVisibility"
import AddProductModalContent from "components/Profile/editModals/AddProductModalContent/AddProductModalContent"
import AddProjectModal from "components/Profile/editModals/AddProjectModal"
import { useContext } from "react"
import { useDispatch } from "react-redux"
import { toggleDialog } from "states/slices/addPostSlice"

const adaptProjectInitialData = data => {
  if (!data) {
    return null
  }

  const adapted = { ...data }
  adapted.project_service_category_ids =
    adapted.project_service_categories?.map(item => item.id)
  adapted.project_function_ids = adapted.project_functions?.map(item => item.id)
  return adapted
}

const AddPostModalContentFormContainer = ({ onSubmit }) => {
  const dispatch = useDispatch()

  const { companyDetails = null } = useContext(AppContext) || {}

  const {
    showPhoto,
    showVideo,
    showDocument,
    showCategory,
    toggleSpaces,
    showSpaces,
    showVisibility,
    disablePrivacy,
    portfolioType,
    onFetch,
    editMode,
    toggleModal,
    communityId,
    setPortfolioType,
  } = useContext(AddPostContext)

  if (showSpaces) {
    return <AddPostSpace onBack={() => toggleSpaces(false)} />
  }

  if (portfolioType?.value === "project") {
    const adaptedData = editMode
      ? adaptProjectInitialData(editMode.project)
      : null

    return (
      <AddProjectModal
        showModal={true}
        initialData={adaptedData}
        companyProfileId={companyDetails?.company_profile_id}
        communityId={communityId}
        refreshData={() => {
          if (onFetch) {
            onFetch()
          }
        }}
        hideModal={() => {
          setPortfolioType(null)
          dispatch(toggleDialog(false))
          if (toggleModal) {
            toggleModal(false)
          }
        }}
      />
    )
  }

  if (portfolioType?.value === "product") {
    return (
      <AddProductModalContent
        showModal={true}
        initialData={editMode?.product}
        companyProfileId={companyDetails?.company_profile_id}
        communityId={communityId}
        refreshData={() => {
          if (onFetch) {
            onFetch()
          }
        }}
        hideModal={() => {
          setPortfolioType(null)
          dispatch(toggleDialog(false))
          if (toggleModal) {
            toggleModal(false)
          }
        }}
      />
    )
  }

  if (showCategory) {
    return <AddPostCategory />
  }

  if (!disablePrivacy && showVisibility) {
    return <AddPostVisibility />
  }

  if (showPhoto || showVideo || showDocument) {
    return <AddAssetModal />
  }

  return <AddPostModalContentForm onSubmit={onSubmit} />
}

export default AddPostModalContentFormContainer
