const Pagination = ({ pageCount, currentPage }) => {
  return (
    <div
      className="absolute flex items-center  bottom-0 mx-3 mb-7"
      style={{ left: "50%", transform: "translateX(-87%)" }}
    >
      {new Array(pageCount).fill().map((_, index) => {
        return <Bullet key={index} selected={index <= currentPage} />
      })}
    </div>
  )
}

const Bullet = ({ selected }) => {
  return (
    <div
      className="rounded-full border border-primary ml-1 flex justify-center items-center"
      style={{ width: 16, height: 16 }}
    >
      {selected && (
        <div
          className="bg-primary rounded-full"
          style={{ width: 8, height: 8 }}
        />
      )}
    </div>
  )
}

export default Pagination
