import { Button } from "antd"
import { useEffect, useMemo, useState } from "react"
import { guid } from "utils/global"

function countLines(id) {
  const el = document.getElementById(id)
  return el.offsetHeight
}

export default function CPEllipsis({
  children,
  lineClamp = 3,
  maxHeight = 100,
  expandable = true,
  hideActions = false,
}) {
  const [showEllipsis, toggleEllipsis] = useState(true)
  const [ellipsisAvailable, toggleAvailability] = useState(false)

  const id = useMemo(() => {
    return guid()
  }, [])

  useEffect(() => {
    if (!children || !expandable) {
      return
    }

    const timer = setTimeout(() => {
      const height = countLines(id)
      toggleAvailability(height > maxHeight)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [children, id])

  return (
    <>
      <div
        id={id}
        className="f-14 whitespace-pre-wrap text-left break-words"
        style={
          ellipsisAvailable && showEllipsis
            ? {
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: lineClamp,
                WebkitBoxOrient: "vertical",
              }
            : {}
        }
      >
        {children}
      </div>

      {ellipsisAvailable && !hideActions && (
        <Button
          type="link"
          className="inline-block p-0 text-primary cursor-pointer f-12"
          onClick={() => toggleEllipsis(!showEllipsis)}
        >
          {showEllipsis ? "Expand" : "Show Less"}
        </Button>
      )}
    </>
  )
}
