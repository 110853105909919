import { Button, Card, Image } from "antd"
import { AppContext } from "components/AppContext"
import AddPostModal from "components/Feed/AddPost/AddPostModal"
import CommentList from "components/Feed/Comments/CommentList"
import ActionsCard from "components/Feed/Posts/ActionsCard"
import ActionsMenu from "components/Feed/Posts/ActionsMenu"
import PostCardConnect from "components/Feed/Posts/PostCardConnect"
import PostCardInfo from "components/Feed/Posts/PostCardInfo"
import Link from "next/link"
import { useContext, useState } from "react"
import { useSelector } from "react-redux"
import apiService from "services/apiService"
import { profileState } from "states/slices/profileReducer"

export default function PostCard({
  children,
  onFetch,
  className,
  previewMode,
  refreshData,
  hideRemoveBookmark,
}) {
  const [loading, setLoading] = useState(false)
  const [hidePost, setHidePost] = useState(false)
  const [collapse, openCollapse] = useState(false)
  const [showModal, toggleEditModal] = useState(false)

  const { userInfo } = useSelector(profileState)
  const { reference, instance, setInstance, item, child } =
    useContext(AppContext) || {}

  const getPostData = async () => {
    try {
      setLoading(true)

      const response = await apiService.get(`/api/network/feed/${item.id}`)
      if (response?.status === 200 && response?.data?.data?.length) {
        setInstance(response?.data?.data[0])
      }
    } catch (e) {
      console.log(e)
      if (onFetch) {
        onFetch()
      }
    }

    setLoading(false)
  }

  return (
    !hidePost && (
      <Card
        loading={loading}
        className={`${className} custom-card f-14 bg-white rounded-lg ${
          !child ? "border border-gray-300" : ""
        }`}
        styles={{
          body: { padding: 0 },
        }}
      >
        {instance && instance.is_pinned && (
          <div className="bg-gray-300 rounded-t-md text-gray-700 px-3 py-1 f-11 flex items-center">
            <Image
              alt="pin"
              width={10}
              height={10}
              preview={false}
              src="/assets/icons/thumbtack-solid.svg"
            />
            <span className="inline-block ml-1">Pined</span>
          </div>
        )}
        <div className="p-3">
          <div className="flex mb-2">
            {!previewMode && child ? (
              <Link
                prefetch={false}
                passHref
                href={
                  child && item.username && item.id
                    ? `/details/${
                        item.company_profile_id ? "company/" : "individual/"
                      }${item.username}/${item.id}`
                    : ""
                }
                key="details"
              >
                <PostCardInfo child />
              </Link>
            ) : (
              <PostCardInfo previewMode={previewMode} />
            )}

            <div className="flex-grow" />

            <div>
              {!previewMode && <PostCardConnect />}
              {!child && instance && (
                <ActionsMenu
                  item={instance}
                  reference={reference}
                  refreshData={refreshData}
                  toggleEditModal={toggleEditModal}
                  hideRemoveBookmark={hideRemoveBookmark}
                  onPin={e => {
                    setInstance({ ...instance, is_pinned: e })
                  }}
                  onHide={e => {
                    setHidePost(e)
                    if (refreshData) {
                      refreshData()
                    }
                  }}
                />
              )}
            </div>
          </div>

          {children}

          {!child && userInfo && (
            <>
              <div className="flex justify-between items-center mt-3 pl-1">
                <ActionsCard
                  type="post"
                  allowReply
                  item={instance}
                  setInstance={setInstance}
                  toggleReplies={openCollapse}
                  toggleEditModal={toggleEditModal}
                />

                <span className="flex-grow" />

                {instance?.comment_visibility !== "no_one" && (
                  <Button
                    type="link"
                    className="px-1 py-0"
                    onClick={() => openCollapse(!collapse)}
                  >
                    {collapse && "Hide"} Comment
                  </Button>
                )}
              </div>
              {collapse && <CommentList />}
            </>
          )}

          {showModal && (
            <AddPostModal
              onFetch={onFetch}
              onUpdate={getPostData}
              showModal={showModal}
              toggleModal={toggleEditModal}
            />
          )}
        </div>
      </Card>
    )
  )
}
