import { Button, Divider } from "antd"
import { AppContext } from "components/AppContext"
import { useContext } from "react"

export default function AddPostSpace({ onBack }) {
  const {
    communitySpaces = null,
    selectedSpace = null,
    setNewSpace,
  } = useContext(AppContext) || {}

  return (
    <>
      <div className="mh-212">
        {communitySpaces?.map((item, index) => (
          <div
            key={index}
            className={`flex items-center py-3 px-5 cursor-pointer hover-gray transition ${
              item?.id === selectedSpace?.id ? "selected-primary" : ""
            }`}
            onClick={() => {
              setNewSpace(item)
            }}
          >
            <div className="flex flex-col ant-text-grey">
              <strong className="f-16">{item?.name} </strong>
              <span className="f-14">{item?.membership_scope}</span>
            </div>
          </div>
        ))}
      </div>

      <Divider className="border-gray-300 m-0" />

      <div className="p-4 flex justify-end">
        <Button
          type="default"
          className="px-8"
          onClick={() => {
            onBack()
          }}
        >
          Back
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          className="px-8"
          onClick={onBack}
        >
          Save
        </Button>
      </div>
    </>
  )
}
