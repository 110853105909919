import { Button, Spin } from "antd"
import dynamic from "next/dynamic"
import { useEffect, useState } from "react"
import apiService from "services/apiService"

const DocumentReader = dynamic(
  () => import("components/CustomFields/DocumentReader")
)

export default function DocumentGallery({ onRemove, item } = {}) {
  const [loading, setLoading] = useState(false)
  const [receivedItem, setReceivedItem] = useState("")

  useEffect(() => {
    if (!item) {
      return setReceivedItem("")
    }
    const fetchData = async () => {
      setLoading(true)
      try {
        const res = await apiService.get(`/api/files/?url=${item.url}`, {
          responseType: "blob",
        })

        setReceivedItem(window.URL.createObjectURL(res.data))
      } catch (e) {
        console.log(e)
      }
      setLoading(false)
    }

    if (!item.file) {
      fetchData()
    } else {
      setReceivedItem(item.file || item.fullUrl || item.url)
    }
  }, [item])

  if (loading) {
    return (
      <div
        style={{ height: 380, backgroundColor: "#eee" }}
        className="rounded-lg border border-gray-300"
      >
        <Spin className="m-3" />
      </div>
    )
  }

  return receivedItem ? (
    <div className="rounded-lg overflow-hidden relative">
      <Button
        style={{ height: 38, width: 38 }}
        className={
          "border-0 rounded-3xl shadow flex items-center justify-center bg-white cursor-pointer absolute z-10 top-0 end-0 m-2"
        }
        onClick={onRemove}
      >
        <span className="icon-ic_delete_24px d-inline-block f-17" />
      </Button>
      <DocumentReader file={receivedItem} />
    </div>
  ) : null
}
