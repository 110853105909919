import CountryAndCitySelector from "components/CustomFields/CountryAndCitySelector"
import ImageGalleryWithCaption from "components/CustomFields/ImageGalleryWithCaption"
import IndividualSelectWithSearch from "components/CustomFields/IndividualSelectWithSearch"
import ProjectFunctionsDropDownField from "components/CustomFields/ProjectFunctionsDropDownField"
import ProjectStatusDropDownField from "components/CustomFields/ProjectStatusDropDownField"
import ProjectTypeDropDownField from "components/CustomFields/ProjectTypeDropDownField"
import ServiceCategoriesDropdownField from "components/CustomFields/ServiceCategoriesDropdownField"
import FormGenerator from "components/FormGenerator"
import ModalPreview from "components/Profile/editModals/ModalPreview"
import { useSubscriptionContext } from "components/Subscription/SubscriptionProvider"
import { useState } from "react"
import apiService from "services/apiService"

const formRules = {
  images: {
    required: "This field is required",
  },
  title: {
    required: "This field is required",
  },
  project_service_category_ids: {
    required: "This field is required",
  },
  project_function_ids: {
    required: "This field is required",
  },
}

const valid = [
  "title",
  "project_description",
  "project_type_id",
  "project_type_other",
  "client",
  "collaborator_ids",
  "collaborator_names",
  "size",
  "country_id",
  "city_id",
  "latitude",
  "longitude",
  "project_status_id",
  "project_function_ids",
  "project_service_category_ids",
  "team_member_ids",
  "team_member_names",
  "images",
  "captions",
  "cover_index",
  "post_on_feed",
  "post_visibility",
  "community_id",
]

export default function AddProjectModal({
  hideModal,
  initialData = null,
  companyProfileId,
  refreshData,
  communityId,
}) {
  const { checkSubscription } = useSubscriptionContext()
  const [showPreviewModal, setShowPreviewModal] = useState(null)

  const formFields = [
    {
      key: "images",
      fieldType: "customSingleField",
      col: {
        xs: 24,
      },
      render: formProps => {
        const {
          register,
          setValue,
          formState: { errors },
        } = formProps
        register("images")
        register("captions")
        register("cover_index")

        return (
          <ImageGalleryWithCaption
            directory="public/portfolio"
            initialData={initialData}
            error={errors?.images && errors?.images?.message}
            onChange={values => {
              setValue(
                "cover_index",
                values?.images?.findIndex(item => item.is_cover)
              )
              setValue(
                "captions",
                values?.images?.map(item => item.caption)
              )
              setValue(
                "images",
                values?.images?.map(item => item?.response?.data || item?.url)
              )
            }}
          />
        )
      },
    },
    {
      key: "title",
      title: "Project Title",
      fieldType: "input",
      col: {
        sm: 12,
        xs: 24,
      },
      placeholder: "Ex: Copenhagen New Library",
    },
    {
      key: "project_service_category_ids",
      title: "Service Category",
      fieldType: "customSingleField",
      col: {
        xs: 12,
      },
      placeholder: "",
      render: formProps => {
        const {
          setValue,
          formState: { errors },
          watch,
        } = formProps
        const value = watch("project_service_category_ids")

        function handleRemove(id) {
          const clonedData = [...(value || [])]
          const i = clonedData?.findIndex(a => a === id)
          if (i >= 0) {
            clonedData?.splice(i, 1)
            setValue("project_service_category_ids", clonedData)
          }
        }

        return (
          <ServiceCategoriesDropdownField
            labelconfig={{
              label: "Service Type",
              error: errors?.project_service_category_ids?.message || null,
              required: formRules?.project_service_category_ids?.required,
            }}
            addNewItem={false}
            value={null}
            onChange={(values, selectedOptions) => {
              const selected = selectedOptions[1] || selectedOptions[0]
              setValue("project_service_category_ids", [
                ...(value || []),
                selected?.value,
              ])
            }}
            selectedValues={value}
            handleRemove={handleRemove}
          />
        )
      },
    },
    {
      key: "project_function_ids",
      title: "Project Function",
      fieldType: "customSingleField",
      col: {
        xs: 12,
      },
      placeholder: "Select Project Function",
      render: formProps => {
        const {
          setValue,
          formState: { errors },
          watch,
        } = formProps
        const value = watch("project_function_ids")

        return (
          <ProjectFunctionsDropDownField
            labelconfig={{
              label: "Project Function",
              error: errors?.project_function_ids?.message || null,
              required: formRules?.project_function_ids?.required,
            }}
            onChange={val => {
              setValue("project_function_ids", val)
            }}
            filterOption={(input, option) =>
              option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA?.label
                ?.toLowerCase()
                .localeCompare(optionB?.label?.toLowerCase())
            }
            mode="multiple"
            value={value}
          />
        )
      },
    },
    {
      key: "project_description",
      title: "Project Description",
      fieldType: "textArea",
      col: {
        xs: 24,
      },
      placeholder: "",
    },
    {
      key: "project_type_id",
      title: "Project Type",
      fieldType: "customSingleField",
      col: {
        sm: 12,
        xs: 24,
      },
      placeholder: "Select Project Type",
      render: formProps => {
        const { setValue, errors, register, watch } = formProps
        register("project_type_title")
        const value = watch("project_type_id")
        const ifWeHaveOther = watch("project_type_other")

        return (
          <ProjectTypeDropDownField
            labelconfig={{
              label: "Project Type",
              error: errors?.project_type_id?.message || null,
              required: formRules?.project_type_id?.required,
            }}
            onChange={(val, e) => {
              setValue("project_type_id", val)
              setValue("project_type_title", e?.label)
            }}
            value={value || (ifWeHaveOther ? 0 : value)}
          />
        )
      },
    },
    {
      key: "project_type_other",
      title: "Custom Project Type",
      fieldType: "input",
      col: {
        sm: 12,
        xs: 24,
      },
      placeholder: "Enter Project Type",
      hideIf: {
        fields: ["project_type_id"],
        condition: targetFieldsVal => !targetFieldsVal?.includes(0),
      },
    },
    {
      key: "city_id",
      title: "Location",
      fieldType: "customSingleField",
      col: {
        xs: 24,
      },
      placeholder: true,
      render: formProps => {
        const { register, setValue, watch } = formProps
        register("city_id")
        register("country_id")

        const country = watch("country_id")
        const city = watch("city_id")

        return (
          <CountryAndCitySelector
            initialData={{
              country_id: country,
              city_id: city,
            }}
            onChange={data => {
              setValue("country_id", data?.country_id)
              setValue("city_id", data?.city_id)
            }}
          />
        )
      },
    },
    {
      key: "project_status_id",
      title: "Project Status",
      fieldType: "customSingleField",
      col: {
        xs: 12,
      },
      placeholder: "Select Project Type",
      render: formProps => {
        const { setValue, errors, register, watch } = formProps
        register("project_status_title")

        const value = watch("project_status_id")

        return (
          <ProjectStatusDropDownField
            labelconfig={{
              label: "Project Status",
              error: errors?.project_status_id?.message || null,
              required: formRules?.project_status_id?.required,
            }}
            onChange={(val, e) => {
              setValue("project_status_id", val)
              setValue("project_status_title", e?.label)
            }}
            value={value}
          />
        )
      },
    },
    {
      key: "size",
      title: "Project Size",
      fieldType: "input",
      col: {
        xs: 12,
      },
      placeholder: "Ex: 1500 sqm",
    },
    {
      key: "client",
      title: "Client",
      fieldType: "input",
      col: {
        xs: 12,
      },
      placeholder: "",
    },
    {
      key: "team_member_ids",
      fieldType: "customSingleField",
      col: {
        xs: 12,
      },
      render: formProps => {
        const { register, setValue, errors, watch } = formProps

        register("team_members")
        register("team_member_names")

        const value = watch("team_members")
        return (
          <IndividualSelectWithSearch
            labelconfig={{
              label: "Team",
              error: errors?.team_member_ids?.message || null,
              required: formRules?.team_member_ids?.required,
            }}
            onChange={e => {
              const values = e?.map(i => i?.value)

              const ids = values?.filter(a => Number.isInteger(a))
              const strings = values?.filter(a => typeof a === "string")

              setValue(
                "team_members",
                e?.map(item => {
                  return { label: item?.label, value: item?.value }
                })
              )
              setValue("team_member_ids", ids)
              setValue("team_member_names", strings)
            }}
            value={value?.map(item => {
              return {
                label: item?.label || item?.team_member_name,
                value:
                  item?.value ||
                  (item?.profile_id
                    ? item?.profile_id
                    : item?.team_member_name),
              }
            })}
          />
        )
      },
    },
    {
      key: "collaborator_ids",
      fieldType: "customSingleField",
      col: {
        sm: 12,
        xs: 24,
      },
      render: formProps => {
        const { register, setValue, errors, watch } = formProps

        register("collaborators")
        register("collaborator_names")

        const value = watch("collaborators")
        return (
          <IndividualSelectWithSearch
            labelconfig={{
              label: "Collaborators",
              error: errors?.collaborator_ids?.message || null,
              required: formRules?.collaborator_ids?.required,
            }}
            onChange={e => {
              const values = e?.map(i => i?.value)

              const ids = values?.filter(a => Number.isInteger(a))
              const strings = values?.filter(a => typeof a === "string")

              setValue(
                "collaborators",
                e?.map(item => {
                  return { label: item?.label, value: item?.value }
                })
              )
              setValue("collaborator_ids", ids)
              setValue("collaborator_names", strings)
            }}
            value={value?.map(item => {
              return {
                label: item?.label || item?.collaborator_name,
                value:
                  item?.value ||
                  (item?.profile_id
                    ? item?.profile_id
                    : item?.collaborator_name),
              }
            })}
          />
        )
      },
    },
  ]

  if (
    !initialData &&
    formFields[formFields.length - 1]?.key !== "post_on_feed"
  ) {
    formFields.push({
      key: "post_on_feed",
      title: "Post on your feed?",
      fieldType: "checkbox",
      col: {
        sm: 24,
        xs: 24,
      },
      placeholder: "",
    })
  }

  const handleSubmitAdd = async body => {
    return await apiService.post(
      `/api/${companyProfileId ? "company/" : "profile/"}${
        companyProfileId ? companyProfileId + "/" : ""
      }projects`,
      body
    )
  }

  const handleSubmitEdit = async body => {
    body.images = body?.images.map(img => img.split("/").pop())
    return await apiService.put(
      `/api/${companyProfileId ? "company/" : "profile/"}${
        companyProfileId ? companyProfileId + "/" : ""
      }projects/${initialData?.id}`,
      body
    )
  }

  const handleSubmit = async values => {
    try {
      values.post_visibility = "public"
      values.post_on_feed = values.post_on_feed || false
      if (communityId) {
        values.community_id = communityId
      }

      const body = {}
      body.images = body?.images?.map(item => item.split("/").pop())

      valid.forEach(item => {
        if (typeof values[item] === "boolean") {
          body[item] = values[item]
        } else if (
          values[item] &&
          (Array.isArray(values[item])
            ? values[item].filter(val => val !== undefined).length !== 0
            : true)
        ) {
          body[item] = values[item]
        }
      })

      if (initialData) {
        await handleSubmitEdit(body)
      } else {
        await handleSubmitAdd(body)
      }

      if (refreshData) {
        refreshData()
      }
      if (hideModal) {
        hideModal()
      }
    } catch (e) {
      checkSubscription(e)
    }
  }

  return (
    <>
      <div className="px-5 pb-5 pt-3">
        <FormGenerator
          fields={formFields}
          rules={formRules}
          initialData={initialData}
          onSubmit={handleSubmit}
          submitText="Save"
          extraButtons={[
            {
              space: true,
              label: "Close",
              onClick: hideModal,
            },
            {
              label: "Preview",
              onClick: formValues => {
                setShowPreviewModal({
                  ...initialData,
                  ...formValues,
                  images: initialData
                    ? formValues?.images
                    : formValues?.images?.map((item, index) => {
                        return {
                          url: item.includes("http")
                            ? item
                            : `${process.env.NEXT_PUBLIC_BASE_URL}/storage/portfolio/${item}`,
                          caption: formValues?.captions?.[index],
                        }
                      }),
                })
              },
            },
          ]}
        />
      </div>
      <ModalPreview
        type="project"
        data={showPreviewModal}
        showModal={!!showPreviewModal}
        hideModal={() => setShowPreviewModal(null)}
        fields={valid}
      />
    </>
  )
}
