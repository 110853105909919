import CPButton from "components/CP/CPButton"
import { useSubscriptionContext } from "components/Subscription/SubscriptionProvider"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import apiService from "services/apiService"
import { profileState } from "states/slices/profileReducer"

export default function ConnectionStatus({
  item,
  style,
  offline,
  onHide,
  onConnection,
  className = "",
}) {
  const router = useRouter()
  const { checkSubscription } = useSubscriptionContext()
  const { userInfo } = useSelector(profileState)
  const isMe = userInfo?.username === item?.username

  const [loading, setLoading] = useState(false)
  const [connectionId, setConnectionId] = useState("")
  const [connectionStatus, setConnectionStatus] = useState("")

  useEffect(() => {
    if (!userInfo || loading || !item || isMe) {
      return
    }
    if (offline || item?.connection || item?.connection_id) {
      setConnectionId(item?.connection_id || item?.connection?.connection_id)
      setConnectionStatus(
        item?.connection_status ||
          item?.connection?.connection_status ||
          item?.status
      )
      return
    }

    if (item?.profile_id || item?.target_profile_id) {
      const fetchStatus = async () => {
        try {
          setLoading(true)
          const res = await apiService.get(
            `/api/network/connection/profile/${
              item?.profile_id || item?.target_profile_id
            }`
          )

          const connectionDetails = res?.data?.data
          const connectionName =
            connectionDetails?.connection_status || connectionDetails?.status

          if (["accepted", "pending"].includes(connectionName?.toLowerCase())) {
            setConnectionStatus(connectionName?.toLowerCase())
            setConnectionId(connectionDetails?.connection_id)
          } else {
            setConnectionId(null)
            setConnectionStatus(null)
          }

          if (onConnection) {
            onConnection(connectionDetails)
          }
        } catch (e) {
          checkSubscription(e)
        }

        setLoading(false)
      }

      fetchStatus()
    }
  }, [userInfo, item, offline])

  const ConnectionText = () => {
    switch (connectionStatus) {
      case "accepted":
        return (
          <>
            <span className="icon-ic_check_24px f-12" /> Following
          </>
        )
      case "pending":
        return <>Pending</>
      default:
        return "Connect"
    }
  }

  const onConnect = async () => {
    if (!item?.profile_id && !item?.target_profile_id) {
      return
    }

    try {
      setLoading(true)
      const body = {
        target_profile_id: item?.profile_id || item?.target_profile_id,
      }
      const { data } = await apiService.post("/api/network/connection", body)

      setConnectionId(data?.data?.id)
      setConnectionStatus(data?.data?.status)
    } catch (e) {
      checkSubscription(e)
    }

    setLoading(false)
  }

  const onRevoke = async () => {
    if (!connectionId) {
      return
    }

    try {
      setLoading(true)
      await apiService.delete(`/api/network/connection/${connectionId}`)

      setConnectionId(null)
      setConnectionStatus(null)
      if (onHide) {
        onHide(true)
      }
    } catch (e) {
      checkSubscription(e)
    }

    setLoading(false)
  }

  const connectionHandler = async () => {
    if (!userInfo) {
      return router.push(`/login?back=${window.location.pathname}`)
    }
    if (loading) {
      return
    }

    if (connectionStatus) {
      await onRevoke()
      return
    }

    await onConnect()
  }

  return (
    !isMe && (
      <CPButton
        type={
          connectionStatus && connectionStatus !== "rejected"
            ? "grey"
            : "primary"
        }
        block
        style={style}
        loading={loading}
        disabled={loading}
        className={`${className} ${
          connectionStatus && connectionStatus !== "rejected" && "text-white"
        }`}
        onClick={connectionHandler}
      >
        {loading ? (
          "Loading"
        ) : (
          <ConnectionText connectionStatus={connectionStatus} />
        )}
      </CPButton>
    )
  )
}
