import Pagination from "components/Pagination"
import FirstPage from "components/Profile/editModals/AddProductModalContent/FirstPage"
import SecondPage from "components/Profile/editModals/AddProductModalContent/SecondPage"
import ModalPreview from "components/Profile/editModals/ModalPreview"
import { useSubscriptionContext } from "components/Subscription/SubscriptionProvider"
import { useState } from "react"
import apiService from "services/apiService"

export default function AddProductModalContent({
  hideModal,
  initialData = null,
  companyProfileId,
  refreshData,
  communityId,
}) {
  const { checkSubscription } = useSubscriptionContext()
  const [showPreviewModal, setShowPreviewModal] = useState(null)
  const [page, setPage] = useState(0)
  const [firstPageValues, setFirstPageValues] = useState([])

  const handleSubmitAdd = async body => {
    return await apiService.post(
      `/api/${companyProfileId ? "company/" : "profile"}${
        companyProfileId || ""
      }/products`,
      body
    )
  }

  const handleSubmitEdit = async body => {
    return await apiService.put(
      `/api/${companyProfileId ? "company/" : "profile/"}${
        companyProfileId ? companyProfileId + "/" : ""
      }products/${initialData?.id}`,
      body
    )
  }

  const validSoftwareService = [
    "title",
    "description",
    "product_category_id",
    "status",
    "availability",
    "service_area_ids",
    "available_from",
    "collaborator_ids",
    "collaborator_names",
    "team_member_ids",
    "team_member_names",
    "buyers",
    "target_audience",
    "read_more_links",
    "images",
    "captions",
    "cover_index",
    "post_on_feed",
    "post_visibility",
    "community_id",
    "space_id",
  ]
  const validPhysicalProduct = [
    "title",
    "description",
    "product_category_id",
    "availability",
    "dimension",
    "manufacture_date",
    "available_from",
    "product_properties_ids",
    "product_material_ids",
    "collaborator_ids",
    "collaborator_names",
    "team_member_ids",
    "team_member_names",
    "buyers",
    "target_audience",
    "read_more_links",
    "images",
    "captions",
    "cover_index",
    "post_on_feed",
    "post_visibility",
    "community_id",
    "space_id",
  ]
  const validPhysicalFacility = [
    "title",
    "description",
    "product_category_id",
    "available_from",
    "measure",
    "collaborator_ids",
    "collaborator_names",
    "team_member_ids",
    "team_member_names",
    "buyers",
    "target_audience",
    "read_more_links",
    "images",
    "captions",
    "cover_index",
    "post_on_feed",
    "post_visibility",
    "community_id",
    "space_id",
  ]
  const validService = [
    "title",
    "description",
    "product_category_id",
    "availability",
    "service_area_ids",
    "available_from",
    "collaborator_ids",
    "collaborator_names",
    "team_member_ids",
    "team_member_names",
    "buyers",
    "target_audience",
    "read_more_links",
    "images",
    "captions",
    "cover_index",
    "post_on_feed",
    "post_visibility",
    "community_id",
    "space_id",
  ]

  const getValidFields = () =>
    firstPageValues.product_category_id?.value === 4
      ? validSoftwareService
      : firstPageValues.product_category_id?.value === 5
        ? validPhysicalProduct
        : firstPageValues.product_category_id?.value === 6
          ? validPhysicalFacility
          : validService

  const handleSubmit = async values => {
    try {
      values.images = firstPageValues.images
      values.title = firstPageValues.title
      values.product_category_id = firstPageValues.product_category_id
      values.cover_index = firstPageValues.cover_index
      values.captions = firstPageValues.captions
      values.post_visibility = "public"
      values.post_on_feed = communityId ? true : values.post_on_feed
      if (communityId) {
        values.community_id = communityId
      }

      const body = {}

      getValidFields().forEach(item => {
        if (typeof values[item] === "boolean") {
          body[item] = values[item]
        } else if (
          values[item] &&
          (Array.isArray(values[item])
            ? values[item].filter(val => val !== undefined).length !== 0
            : true)
        ) {
          body[item] = values[item]
        }
      })

      body.images = body?.images?.map(item => item.split("/").pop())
      body.product_material_ids = values?.product_material_ids?.map(
        material => material.value
      )
      body.product_category_id = values?.product_category_id?.value
      body.service_area_ids = values?.service_areas?.map(item => item.id)

      if (initialData) {
        await handleSubmitEdit(body)
      } else {
        await handleSubmitAdd(body)
      }

      if (refreshData) {
        refreshData()
      }
      if (hideModal) {
        hideModal()
      }
    } catch (e) {
      checkSubscription(e)
    }
  }

  const nextPage = values => {
    setFirstPageValues(values)
    setPage(1)
  }

  const previousPage = function (_, formProps) {
    formProps.reset()
    setPage(0)
  }

  const firstPageMarkup = (
    <FirstPage
      initialData={initialData}
      onSubmit={nextPage}
      submitText="Next"
      extraButtons={[
        {
          space: true,
          label: "Close",
          onClick: hideModal,
        },
      ]}
    />
  )

  const secondPageMarkup = (
    <SecondPage
      forCommunity={!!communityId}
      initialData={initialData}
      type={
        firstPageValues.product_category_id?.value === 4
          ? "softwareServicesForm"
          : firstPageValues.product_category_id?.value === 5
            ? "physicalProductForm"
            : firstPageValues.product_category_id?.value === 6
              ? "physicalFacilityForm"
              : "serviceForm"
      }
      onSubmit={handleSubmit}
      submitText="Save"
      extraButtons={[
        {
          space: true,
          label: "Back",
          onClick: previousPage,
        },
        {
          space: false,
          label: "Preview",
          onClick: (_, formProps) => {
            const values = buildPreviewData(formProps)
            setShowPreviewModal(values)
          },
        },
      ]}
    />
  )

  return (
    <>
      <div className="px-5 pb-5 pt-3">
        {page === 0 ? firstPageMarkup : secondPageMarkup}
      </div>
      <ModalPreview
        type="product"
        fields={getValidFields()}
        data={showPreviewModal}
        showModal={!!showPreviewModal}
        hideModal={() => setShowPreviewModal(null)}
      />
      <Pagination pageCount={2} currentPage={page} />
    </>
  )

  function buildPreviewData(formProps) {
    const formValues = formProps?.control?._formValues
    const values = formValues
    values.title = firstPageValues.title
    values.post_visibility = "public"
    values.post_on_feed = communityId ? true : values.post_on_feed
    if (communityId) {
      values.community_id = communityId
    }

    values.product_category = firstPageValues?.product_category_id
    values.product_materials = formValues?.product_material_ids

    values.collaborators = formValues?.collaborators?.map(collaborator => {
      return { collaborator_name: collaborator.label ?? collaborator.value }
    })
    values.team_members = formValues?.team_members?.map(member => {
      return { team_member_name: member.label ?? member.value }
    })
    values.images = firstPageValues?.images.map((item, index) => {
      return {
        url: item.includes("http")
          ? item
          : `${process.env.NEXT_PUBLIC_BASE_URL}/storage/product/${item}`,
        caption: firstPageValues.captions[index],
      }
    })

    return values
  }
}
