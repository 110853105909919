import { Space } from "antd"
import { AppContext } from "components/AppContext"
import CPEllipsis from "components/CP/CPEllipsis"
import LinkPreview from "components/Editor/LinkPreview"
import PortfolioCard from "components/Feed/Posts/PortfolioCard"
import PostCardFiles from "components/Feed/Posts/PostCardFiles"
import ReferenceCard from "components/Feed/Posts/ReferenceCard"
import DOMPurify from "dompurify"
import Link from "next/link"
import { Fragment, useContext } from "react"
import { replaceLinksInText } from "utils/global"

export default function PostCardContent({
  setReference,
  showReference,
  expandable = true,
}) {
  const {
    instance,
    reference,
    setInstance,
    companyDetails = null,
  } = useContext(AppContext) || {}
  const replacedBody = instance?.body
    ? replaceLinksInText(instance?.body)
    : null

  return (
    <>
      <div className="px-1">
        {instance?.title && (
          <div className="mt-4 mb-1 font-bold text-gray-700 text-[16px]">
            {instance?.title}
          </div>
        )}
        {replacedBody && (
          <>
            {String(instance?.body)?.search("<iframe") >= 0 ? (
              <div className="iframe-contents my-2">
                <div
                  className="f-14 whitespace-pre-wrap text-left px-1 break-words"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(instance?.body, {
                      ADD_TAGS: ["iframe"],
                      ADD_ATTR: [
                        "allow",
                        "allowfullscreen",
                        "frameborder",
                        "scrolling",
                      ],
                    }),
                  }}
                />
              </div>
            ) : (
              <CPEllipsis expandable={expandable}>
                <div
                  className="text-gray-700"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(replacedBody, {
                      ADD_ATTR: ["target"],
                    }),
                  }}
                />
              </CPEllipsis>
            )}
          </>
        )}
      </div>

      {instance?.hashtags && instance.hashtags.length !== 0 && (
        <Space className="f-16 mt-3" size={10}>
          {instance.hashtags.map((tag, index) => (
            <Fragment key={`${tag?.id}-${index}`}>
              <Link passHref prefetch={false} href="/">
                <span className="cursor-pointer f-14 text-blue-500">
                  #{tag.name}
                </span>
              </Link>
            </Fragment>
          ))}
        </Space>
      )}

      {instance?.files?.length && (
        <div className="my-2 rounded-lg overflow-hidden">
          <PostCardFiles files={instance?.files} />
        </div>
      )}

      {showReference && instance?.reference_post_id && (
        <ReferenceCard
          className="my-3"
          postId={instance?.id}
          reference={reference}
          showReference={false}
          setReference={setReference}
          referenceId={instance?.reference_post_id}
        />
      )}

      {["product", "project"].includes(instance?.post_category) && (
        <PortfolioCard
          post={instance}
          companyId={companyDetails?.profile_id || instance?.company_profile_id}
          setInstance={event => setInstance({ ...instance, portfolio: event })}
        />
      )}

      <LinkPreview
        className="mx-1 mb-2 mt-4 border border-gray-300"
        body={instance?.body}
      />
    </>
  )
}
