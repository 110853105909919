import { AddPostContext } from "components/Feed/AddPost/AddPostContext"
import AddPostModalContentFormContainer from "components/Feed/AddPost/AddPostModalContentFormContainer"
import { useSubscriptionContext } from "components/Subscription/SubscriptionProvider"
import { useContext } from "react"
import { useDispatch } from "react-redux"
import apiService from "services/apiService"
import { notifyUser } from "services/notifyToast"
import { toggleDialog } from "states/slices/addPostSlice"

const AddPostModalContent = () => {
  const {
    title,
    description,
    savedPhoto,
    savedVideo,
    savedDocument,
    category,
    visibility,
    referencePost,
    communityId,
    companyId,
    setLoading,
    editMode,
    onUpdate,
    onFetch,
    toggleModal,
  } = useContext(AddPostContext)

  const dispatch = useDispatch()
  const { checkSubscription } = useSubscriptionContext()

  const updateParent = () => {
    if (editMode && onUpdate) {
      onUpdate()
    } else if (onFetch) {
      onFetch()
    }

    dispatch(toggleDialog(false))

    if (toggleModal) {
      toggleModal(false)
    }
  }

  const onSubmit = async () => {
    const files = savedPhoto.length
      ? savedPhoto
      : savedVideo.length
        ? savedVideo
        : savedDocument.length
          ? savedDocument
          : []
    const post_files = files.map(item => ({ url: item?.response || item.url }))

    if (!(title || description) && files.length === 0) {
      return notifyUser({
        type: "error",
        description: "Upload or post something on your feed.",
      })
    }

    try {
      setLoading(true)
      const body = {
        post_files: post_files.length ? post_files : null,
        hashtags: [],
        body: description,
        title,
        reference_post_id: referencePost
          ? referencePost.reference_post_id || referencePost.id
          : null,
        post_category: category?.value || "quick_post",
        post_visibility:
          visibility?.value === "community"
            ? "public"
            : visibility?.value || "public",
        comment_visibility: "public",
      }

      if (referencePost) {
        await apiService.post(
          `/api/${
            communityId
              ? `community/${communityId}`
              : `network${companyId ? `/company/${companyId}` : ""}`
          }/post`,
          body
        )
      } else {
        await apiService.post(
          `/api/${
            communityId
              ? `community/${communityId}`
              : `network${companyId ? `/company/${companyId}` : ""}`
          }/post`,
          body
        )
      }

      notifyUser({
        type: "success",
        description: "Your post submitted successfully",
      })

      updateParent()
    } catch (e) {
      checkSubscription(e)
      setLoading(false)
    }
  }

  return <AddPostModalContentFormContainer onSubmit={onSubmit} />
}

export default AddPostModalContent
