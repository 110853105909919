import { Avatar, Button, Card, Popconfirm, Typography } from "antd"
import { AppContext } from "components/AppContext"
import CommentChildList from "components/Feed/Comments/CommentChildList"
import AbuseReport from "components/Feed/Posts/AbuseReport"
import ActionsCard from "components/Feed/Posts/ActionsCard"
import { useSubscriptionContext } from "components/Subscription/SubscriptionProvider"
import parse from "html-react-parser"
import moment from "moment"
import { useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import apiService from "services/apiService"
import { communityReduxState } from "states/slices/communitySlice"
import { profileState } from "states/slices/profileReducer"
import { truncate } from "utils/global"

const { Text, Paragraph } = Typography

const CommentCard = ({
  item,
  mine,
  large,
  postId,
  orange,
  companyId,
  fetchData,
  onRemove,
  allowReply = true,
} = {}) => {
  const { checkSubscription } = useSubscriptionContext()
  const [loading, setLoading] = useState(false)
  const [instance, setInstance] = useState(null)
  const [showReplies, toggleReplies] = useState(false)
  const [showAbuseReport, toggleAbuseReport] = useState(false)

  const { userInfo } = useSelector(profileState)
  const { communityDetails = null } = useContext(AppContext) || {}

  const commentUserId = instance?.creator_user_id || instance?.user_id
  const isMe = mine || commentUserId === userInfo?.user_id

  const { userPolicies } = useSelector(communityReduxState)
  const removeCommentPermission = userPolicies?.some(policy =>
    ["delete_comment"].includes(policy)
  )

  const body = instance?.body ? parse(instance?.body) : ""

  const removeComment = async () => {
    try {
      setLoading(true)
      await apiService.delete(`/api/network/post/${postId}/comment/${item.id}`)
      if (onRemove) {
        onRemove()
      }
      if (fetchData) {
        fetchData()
      }
    } catch (e) {
      checkSubscription(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!item) {
      return
    }

    setInstance(item)
  }, [item])

  return (
    <div>
      <Card
        className="border-none"
        styles={{ body: { padding: 12 } }}
        style={{
          borderRadius: 15,
          backgroundColor: orange ? "#feedca" : "#f2f2f2",
        }}
      >
        <div className="flex items-center">
          <Avatar
            size={large ? 60 : 49}
            style={{ minWidth: large ? 60 : 49 }}
            className="bg-gray-300"
            src={
              instance?.profile_photo || "/assets/onBoarding/profile_avatar.jpg"
            }
            alt={instance?.full_name}
          />
          <div className="flex flex-col ml-4">
            <Text className="f-16 font-medium text-black block">
              {instance?.full_name}
            </Text>

            <div className="flex">
              {instance?.headline && (
                <span className="f-13 block text-gray-500 mr-3">
                  {truncate(instance?.headline || "-", { length: 35 })}
                </span>
              )}
              <span className="f-13 block text-gray-400">
                {moment(instance?.created_at).fromNow()}
              </span>
            </div>
          </div>
        </div>
        <Paragraph
          ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
          className="f-14 mt-3 whitespace-pre-wrap text-justify px-1"
        >
          {body}
        </Paragraph>
      </Card>

      <div className={allowReply && showReplies ? "pl-16" : "pl-3"}>
        <div className="flex items-center mb-2 pl-2 pr-3">
          <ActionsCard
            type="comment"
            item={instance}
            allowReply={allowReply}
            toggleReplies={() => toggleReplies(!showReplies)}
            setInstance={setInstance}
          />

          <span className="flex-grow" />

          {(isMe || (communityDetails && removeCommentPermission)) && (
            <Popconfirm
              disabled={loading}
              title="Are you sure you want to delete this comment?"
              okText="Yes"
              cancelText="No"
              className="mr-4 cursor-pointer hover:text-primary"
              onConfirm={removeComment}
            >
              <span>Delete</span>
            </Popconfirm>
          )}

          {allowReply && (
            <Button
              type="link"
              className="px-2 hover:text-primary m-0 bg-white"
              onClick={() => toggleReplies(!showReplies)}
            >
              Reply to
            </Button>
          )}

          {!!instance && !isMe && (
            <>
              <span className="mx-2"> | </span>
              <Button
                type="link"
                className="px-2 hover:text-primary m-0 bg-white"
                onClick={() => {
                  toggleAbuseReport(true)
                }}
              >
                Report Comment
              </Button>
              <AbuseReport
                report_id={instance?.id}
                report_type="comment"
                showModal={showAbuseReport}
                closeModal={() => {
                  toggleAbuseReport(false)
                }}
              />
            </>
          )}
        </div>

        {allowReply && showReplies && (
          <CommentChildList
            postId={postId}
            instance={instance}
            onRemove={onRemove}
            companyId={companyId}
            setInstance={setInstance}
          />
        )}
      </div>
    </div>
  )
}

export default CommentCard
