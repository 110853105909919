import { Image } from "antd"

export default function Cover({ img = "", className = "", children }) {
  return (
    <Image
      width={"100%"}
      preview={false}
      src={img || "/assets/onBoarding/profile_cover.jpg"}
      className={`${className ? className : `${className}`} overflow-hidden border bg-gray-100`}
    >
      {children}
    </Image>
  )
}
