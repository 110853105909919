import { CPModal } from "components/CP/CPModal"
import AddProjectModalContent from "components/Profile/editModals/AddProjectModalContent"

export default function AddProjectModal({
  hideModal,
  initialData = null,
  showModal,
  companyProfileId,
  refreshData,
  communityId,
}) {
  return (
    <CPModal
      centered
      className="rounded-lg pb-5"
      width={850}
      footer={false}
      destroyOnClose
      style={{ top: 20 }}
      maskClosable={false}
      onCancel={hideModal}
      open={showModal}
      title={initialData ? "Edit Project" : "Add Project"}
    >
      {showModal && (
        <AddProjectModalContent
          initialData={initialData}
          companyProfileId={companyProfileId}
          refreshData={refreshData}
          communityId={communityId}
          hideModal={hideModal}
        />
      )}
    </CPModal>
  )
}
