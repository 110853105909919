import { Button, Spin } from "antd"
import dynamic from "next/dynamic"
import { useEffect, useState } from "react"
import apiService from "services/apiService"

const VideoPlayer = dynamic(() => import("components/CustomFields/VideoPlayer"))

export default function VideoGallery({ onRemove, item } = {}) {
  const [loading, setLoading] = useState(false)
  const [receivedItem, setReceivedItem] = useState("")

  useEffect(() => {
    if (!item) {
      return setReceivedItem("")
    }
    const fetchData = async () => {
      try {
        setLoading(true)
        const res = await apiService.get(`/api/files/?url=${item.url}`, {
          responseType: "blob",
        })

        setReceivedItem(window.URL.createObjectURL(res.data))
      } catch (e) {
        console.log(e)
      }

      setLoading(false)
    }

    if (!item.file) {
      fetchData()
    } else {
      setReceivedItem(item.file || item.fullUrl || item.url)
    }
  }, [item])

  if (loading) {
    return (
      <div
        style={{ height: 300, backgroundColor: "#eee" }}
        className="rounded-lg border border-gray-300"
      >
        <Spin className="m-3" />
      </div>
    )
  }

  return (
    receivedItem && (
      <div className="rounded-lg overflow-hidden relative">
        <div className="absolute top-0 right-0 z-10 m-2">
          <Button
            type="light"
            style={{ height: 38, width: 38 }}
            className="roudend-lg shadow bg-white"
            icon={<span className="icon-ic_delete_24px f-15" />}
            onClick={onRemove}
          />
        </div>
        <VideoPlayer
          options={{
            playbackRate: 1,
            autoplay: false,
            controls: true,
            responsive: true,
            fluid: true,
            sources: [
              {
                src: receivedItem,
                type: "video/mp4",
              },
            ],
          }}
        />
      </div>
    )
  )
}
