import { Col, Image, Row } from "antd"
import DocumentGallery from "components/CustomFields/DocumentGallery"
import ImageGallery from "components/CustomFields/ImageGallery"
import VideoGallery from "components/CustomFields/VideoGallery"
import { AddPostContext } from "components/Feed/AddPost/AddPostContext"
import { useContext } from "react"

const { PreviewGroup } = Image

export default function AddPostModalContentFormFiles() {
  const {
    savedPhoto,
    setSavedPhoto,
    savedVideo,
    setSavedVideo,
    savedDocument,
    setSavedDocument,
  } = useContext(AddPostContext)

  if (savedPhoto.length > 0) {
    return (
      <Row gutter={[8, 4]} className="rounded-lg overflow-hidden mt-4">
        <PreviewGroup>
          {savedPhoto.map((item, index) => (
            <ImageGallery
              key={index}
              index={index}
              getFile={!!item.url}
              onGetImage={({ file, index }) => {
                const updatedPhotos = [...savedPhoto]
                updatedPhotos[index] = { file, url: updatedPhotos[index].url }
                setSavedPhoto(updatedPhotos)
              }}
              src={item.file || item.url}
              fileLength={savedPhoto.length}
              onRemove={() =>
                setSavedPhoto(savedPhoto.filter((_, i) => i !== index))
              }
            />
          ))}
        </PreviewGroup>
      </Row>
    )
  }

  if (savedVideo.length > 0) {
    return (
      <Row gutter={[8, 4]} className="rounded-lg overflow-hidden mt-4">
        {savedVideo.map((item, index) => (
          <Col xs={24} key={index}>
            <VideoGallery item={item} onRemove={() => setSavedVideo([])} />
          </Col>
        ))}
      </Row>
    )
  }

  if (savedDocument.length > 0) {
    return (
      <Row gutter={[8, 4]} className="rounded-lg overflow-hidden mt-4">
        {savedDocument.map((item, index) => (
          <Col xs={24} key={index}>
            <DocumentGallery
              item={item}
              onRemove={() => setSavedDocument([])}
            />
          </Col>
        ))}
      </Row>
    )
  }

  return null
}
