import { Cascader } from "antd"
import CPInputWithLabelHOC from "components/CP/CPInputWithLabelHOC"
import { useEffect, useState } from "react"
import styled from "styled-components"

const CustomCascader = styled(Cascader)`
  width: 100%;
  min-height: 40px !important;
`

function CPCascader({ search, ...props }) {
  const [filteredData, setFilteredData] = useState("")
  const [renderedData, setRenderedData] = useState([])

  useEffect(() => {
    if (props?.data) {
      setRenderedData(props?.data)
    }
  }, [props.data])

  useEffect(() => {
    if (!filteredData) {
      return setRenderedData(props.data)
    }

    const allTitles = props?.data?.map(item => item.title)
    props?.data?.forEach(item =>
      item.children.forEach(child => allTitles.push(child.title))
    )

    const result = allTitles.some(
      option => option?.toLowerCase().indexOf(filteredData?.toLowerCase()) > -1
    )

    if (result) {
      setRenderedData(props.data)
    } else {
      setRenderedData([
        ...props.data,
        {
          value: null,
          label: "New",
          children: [
            {
              value: filteredData,
              label: filteredData,
            },
          ],
        },
      ])
    }
  }, [filteredData])

  return (
    <CustomCascader
      {...props}
      options={props.options || renderedData}
      showSearch={
        search
          ? {
              filter: (inputValue, path) => {
                if (filteredData !== inputValue) {
                  setFilteredData(inputValue)
                }
                return path?.some(option =>
                  option?.label
                    ?.toLowerCase()
                    .includes(inputValue?.toLowerCase())
                )
              },
            }
          : false
      }
    />
  )
}

export default CPInputWithLabelHOC(CPCascader)
