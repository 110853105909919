import CPSelect from "components/CP/CPSelect"
import IndividualSelectWithSearch from "components/CustomFields/IndividualSelectWithSearch"
import ServiceAreasDropdownField from "components/CustomFields/ServiceAreasDropdownField"
import FormGenerator from "components/FormGenerator"

const buildSoftwareServiceAreasField = () => ({
  key: "service_area_ids",
  title: "Service areas",
  fieldType: "customSingleField",
  col: {
    sm: 12,
    xs: 24,
  },
  placeholder: "Select Product service areas",
  render: formProps => {
    const {
      setValue,
      formState: { errors },
      watch,
    } = formProps
    const value = watch("service_areas")

    function handleRemove(id) {
      const clonedData = [...(value || [])]
      const i = clonedData?.findIndex(a => a === id)
      if (i >= 0) {
        clonedData?.splice(i, 1)
        setValue("service_areas", clonedData)
      }
    }

    return (
      <ServiceAreasDropdownField
        type={"software"}
        labelconfig={{
          label: "Service areas",
          error: errors?.service_areas?.message || null,
          required: formRules?.service_areas?.required,
        }}
        addNewItem={false}
        value={null}
        onChange={(values, selectedOptions) => {
          setValue("service_areas", [
            ...new Set([
              ...(value || []),
              {
                id: selectedOptions[0].value,
                title: selectedOptions[0].label,
              },
            ]),
          ])
        }}
        selectedValues={value}
        handleRemove={handleRemove}
      />
    )
  },
})
const buildStatusField = () => ({
  key: "status",
  title: "Product status",
  fieldType: "customSingleField",
  col: {
    sm: 12,
    xs: 24,
  },
  placeholder: "Select Product Status",
  render: formProps => {
    const {
      setValue,
      formState: { errors },
      watch,
    } = formProps
    const value = watch("status")

    return (
      <CPSelect
        labelconfig={{
          label: "Product status",
          error: errors?.status?.message || null,
          required: formRules?.status?.required,
        }}
        onChange={val => {
          setValue("status", val)
        }}
        value={value}
        options={[
          { label: "Beta", value: "Beta" },
          { label: "Scale up", value: "Scale up" },
          { label: "Mature", value: "Mature" },
        ]}
      />
    )
  },
})
const buildServiceAvailabilityField = () => ({
  key: "availability",
  title: "Service availability",
  fieldType: "customSingleField",
  col: {
    sm: 12,
    xs: 24,
  },
  placeholder: "Select Service Availability",
  render: formProps => {
    const {
      setValue,
      formState: { errors },
      watch,
    } = formProps
    const value = watch("availability")

    return (
      <CPSelect
        labelconfig={{
          label: "Service availability",
          error: errors?.availability?.message || null,
          required: formRules?.availability?.required,
        }}
        onChange={val => {
          setValue("availability", val)
        }}
        value={value}
        options={[
          { label: "Globally", value: "Globally" },
          { label: "Nationally", value: "Nationally" },
          { label: "Regionally", value: "Regionally" },
          { label: "Locally", value: "Locally" },
          { label: "No service available", value: "No service available" },
        ]}
      />
    )
  },
})
const buildBuyersField = () => ({
  key: "buyers",
  title: "Product buyers",
  fieldType: "customSingleField",
  col: {
    sm: 12,
    xs: 24,
  },
  placeholder: "Select buyers",
  render: formProps => {
    const {
      setValue,
      formState: { errors },
      watch,
    } = formProps
    const value = watch("buyers")

    return (
      <CPSelect
        labelconfig={{
          label: "Product buyers",
          error: errors?.buyers?.message || null,
          required: formRules?.buyers?.required,
        }}
        onChange={val => {
          setValue("buyers", val)
        }}
        value={value}
        options={[
          {
            label: "Other businesses (B2B)",
            value: "Other businesses (B2B)",
          },
          { label: "Consumers (B2C)", value: "Consumers (B2C)" },
          {
            label: "Consumers via other businesses (B2B2C)",
            value: "Consumers via other businesses (B2B2C)",
          },
        ]}
      />
    )
  },
})
const buildTargetAudienceField = () => ({
  key: "target_audience",
  title: "Target audience",
  fieldType: "input",
  col: {
    sm: 12,
    xs: 24,
  },
  placeholder: "",
})
const buildReadMoreLinksField = () => ({
  key: "read_more_links",
  title: "Read more links",
  fieldType: "input",
  col: {
    sm: 12,
    xs: 24,
  },
  placeholder: "",
})
const buildAvailableFromField = () => ({
  key: "available_from",
  title: "Available from Date",
  fieldType: "datePicker",
  col: {
    sm: 12,
    xs: 24,
  },
  placeholder: "",
})
const buildTeamMemberIdsField = () => ({
  key: "team_member_ids",
  fieldType: "customSingleField",
  col: {
    xs: 12,
  },
  render: formProps => {
    const {
      register,
      setValue,
      formState: { errors },
      watch,
    } = formProps

    register("team_members")
    register("team_member_names")

    const value = watch("team_members")
    return (
      <IndividualSelectWithSearch
        labelconfig={{
          label: "Team",
          error: errors?.team_member_ids?.message || null,
          required: formRules?.team_member_ids?.required,
        }}
        onChange={e => {
          const values = e?.map(i => i?.value)

          const ids = values?.filter(a => Number.isInteger(a))
          const strings = values?.filter(a => typeof a === "string")

          setValue(
            "team_members",
            e?.map(item => {
              return { label: item?.label, value: item?.value }
            })
          )
          setValue("team_member_ids", ids)
          setValue("team_member_names", strings)
        }}
        value={value?.map(item => {
          return {
            label: item?.label || item?.team_member_name,
            value:
              item?.value ||
              (item?.profile_id ? item?.profile_id : item?.team_member_name),
          }
        })}
      />
    )
  },
})
const buildCollaboratorIdsField = () => ({
  key: "collaborator_ids",
  fieldType: "customSingleField",
  col: {
    sm: 12,
    xs: 24,
  },
  render: formProps => {
    const {
      register,
      setValue,
      formState: { errors },
      watch,
    } = formProps

    register("collaborators")
    register("collaborator_names")

    const value = watch("collaborators")
    return (
      <IndividualSelectWithSearch
        labelconfig={{
          label: "Collaborators",
          error: errors?.collaborator_ids?.message || null,
          required: formRules?.collaborator_ids?.required,
        }}
        onChange={e => {
          const values = e?.map(i => i?.value)

          const ids = values?.filter(a => Number.isInteger(a))
          const strings = values?.filter(a => typeof a === "string")

          setValue(
            "collaborators",
            e?.map(item => {
              return { label: item?.label, value: item?.value }
            })
          )
          setValue("collaborator_ids", ids)
          setValue("collaborator_names", strings)
        }}
        value={value?.map(item => {
          return {
            label: item?.label || item?.collaborator_name,
            value:
              item?.value ||
              (item?.profile_id ? item?.profile_id : item?.collaborator_name),
          }
        })}
      />
    )
  },
})
const buildDescriptionField = () => ({
  key: "description",
  title: "Description",
  fieldType: "textArea",
  col: {
    xs: 24,
  },
  placeholder: "",
})
const buildPostOnFeedFiled = () => ({
  key: "post_on_feed",
  title: "Post on your feed?",
  fieldType: "checkbox",
  col: {
    sm: 24,
    xs: 24,
  },
  placeholder: "",
})

const formRules = {
  images: {
    required: "This field is required",
  },
  title: {
    required: "This field is required",
  },
  dimension: {
    required: "This field is required",
  },
  description: {
    required: "This field is required",
  },
  product_category_id: {
    required: "This field is required",
  },
  product_properties_ids: {
    required: "This field is required",
  },
  product_material_ids: {
    required: "This field is required",
  },
  product_service_category_ids: {
    required: "This field is required",
  },
  manufacture_date: {
    required: "This field is required",
  },
}
export const SoftwareServicesForm = props => {
  const { forCommunity, initialData } = props

  const adaptedInitialData = JSON.parse(JSON.stringify(initialData))

  const service_areas = props.initialData?.service_areas

  if (service_areas) {
    adaptedInitialData.service_area_ids = service_areas.map(item => item.id)
  }

  const fields = [
    buildDescriptionField(initialData),
    buildStatusField(initialData),
    buildSoftwareServiceAreasField(initialData),
    buildServiceAvailabilityField(initialData),
    buildAvailableFromField(initialData),
    buildTeamMemberIdsField(initialData),
    buildBuyersField(initialData),
    buildCollaboratorIdsField(initialData),
    buildTargetAudienceField(initialData),
    buildReadMoreLinksField(initialData),
  ]
  if (!forCommunity) {
    fields.push(buildPostOnFeedFiled(initialData))
  }
  return (
    <FormGenerator
      fields={fields}
      rules={formRules}
      {...props}
      initialData={adaptedInitialData}
    />
  )
}
