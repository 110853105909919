import { Skeleton } from "antd"
import PostCard from "components/Feed/Posts/PostCard"
import { useEffect, useState } from "react"
import apiService from "services/apiService"

export default function ReferenceCard({
  postId,
  referenceId,
  className = "",
  expandable = true,
  reference,
  previewMode,
  setReference,
  showReference = true,
}) {
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    if (loading || !postId || !referenceId || reference) {
      return
    }

    try {
      setLoading(true)
      const { data } = await apiService.get(
        `/api/network/feed/${postId}/reference/${referenceId}`
      )

      if (data?.data) {
        data.data.reference_post_id = null
        setReference(data?.data)
      }
    } catch (e) {
      console.log(e)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (referenceId && postId) {
      fetchData()
    }
  }, [postId, referenceId])

  return loading ? (
    <Skeleton className="small-skeleton" active paragraph={{ rows: 4 }} />
  ) : (
    reference && (
      <div
        className={`${className} rounded-lg`}
        style={{
          boxShadow: "0 5px 15px 0 rgba(0, 0, 0, 0.2)",
        }}
      >
        <PostCard
          item={reference}
          previewMode={previewMode}
          expandable={expandable}
          showReference={showReference}
          child
        />
      </div>
    )
  )
}
