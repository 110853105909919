import CPSelect from "components/CP/CPSelect"
import IndividualSelectWithSearch from "components/CustomFields/IndividualSelectWithSearch"
import PropertiesDropdownField from "components/CustomFields/PropertiesDropdownField"
import FormGenerator from "components/FormGenerator"

export const PhysicalFacilityForm = props => {
  const { forCommunity, initialData } = props

  const buildBuyersField = () => ({
    key: "buyers",
    title: "Product buyers",
    fieldType: "customSingleField",
    col: {
      sm: 12,
      xs: 24,
    },
    placeholder: "Select buyers",
    render: formProps => {
      const {
        setValue,
        formState: { errors },
        watch,
      } = formProps
      const value = watch("buyers")

      return (
        <CPSelect
          labelconfig={{
            label: "Product buyers",
            error: errors?.buyers?.message || null,
            required: formRules?.buyers?.required,
          }}
          onChange={val => {
            setValue("buyers", val)
          }}
          value={value}
          options={[
            {
              label: "Other businesses (B2B)",
              value: "Other businesses (B2B)",
            },
            { label: "Consumers (B2C)", value: "Consumers (B2C)" },
            {
              label: "Consumers via other businesses (B2B2C)",
              value: "Consumers via other businesses (B2B2C)",
            },
          ]}
        />
      )
    },
  })
  const buildMeasureField = () => ({
    key: "measure",
    title: "Measure",
    fieldType: "input",
    col: {
      sm: 12,
      xs: 24,
    },
    placeholder: "",
  })
  const buildTargetAudienceField = () => ({
    key: "target_audience",
    title: "Target audience",
    fieldType: "input",
    col: {
      sm: 12,
      xs: 24,
    },
    placeholder: "",
  })
  const buildReadMoreLinksField = () => ({
    key: "read_more_links",
    title: "Read more links",
    fieldType: "input",
    col: {
      sm: 12,
      xs: 24,
    },
    placeholder: "",
  })
  const buildAvailableFromField = () => ({
    // key: "available_from",
    // title: "Available from Date",
    // fieldType: "datePicker",
    // col: {
    //   sm: 12,
    //   xs: 24,
    // },
    // placeholder: "",
    key: "product_properties_ids",
    title: "Product Properties",
    fieldType: "customSingleField",
    col: {
      xs: 12,
    },
    placeholder: "",
    render: formProps => {
      const {
        setValue,
        formState: { errors },
        watch,
      } = formProps
      const value = watch("product_properties_ids")

      function handleRemove(id) {
        const clonedData = [...(value || [])]
        const i = clonedData?.findIndex(a => a === id)
        if (i >= 0) {
          clonedData?.splice(i, 1)
          setValue("product_properties_ids", clonedData)
        }
      }

      return (
        <PropertiesDropdownField
          labelconfig={{
            label: "Product Properties",
            error: errors?.product_properties_ids?.message || null,
            required: formRules?.product_properties_ids?.required,
          }}
          value={null}
          onChange={values => {
            setValue("product_properties_ids", [
              ...new Set([...(value || []), ...values]),
            ])
          }}
          selectedValues={value}
          handleRemove={handleRemove}
        />
      )
    },
  })
  const buildTeamMemberIdsField = () => ({
    key: "team_member_ids",
    fieldType: "customSingleField",
    col: {
      xs: 12,
    },
    render: formProps => {
      const {
        register,
        setValue,
        formState: { errors },
        watch,
      } = formProps

      register("team_members")
      register("team_member_names")

      const value = watch("team_members")
      return (
        <IndividualSelectWithSearch
          labelconfig={{
            label: "Team",
            error: errors?.team_member_ids?.message || null,
            required: formRules?.team_member_ids?.required,
          }}
          onChange={e => {
            const values = e?.map(i => i?.value)

            const ids = values?.filter(a => Number.isInteger(a))
            const strings = values?.filter(a => typeof a === "string")

            setValue(
              "team_members",
              e?.map(item => {
                return { label: item?.label, value: item?.value }
              })
            )
            setValue("team_member_ids", ids)
            setValue("team_member_names", strings)
          }}
          value={value?.map(item => {
            return {
              label: item?.label || item?.team_member_name,
              value:
                item?.value ||
                (item?.profile_id ? item?.profile_id : item?.team_member_name),
            }
          })}
        />
      )
    },
  })
  const buildCollaboratorIdsField = () => ({
    key: "collaborator_ids",
    fieldType: "customSingleField",
    col: {
      sm: 12,
      xs: 24,
    },
    render: formProps => {
      const {
        register,
        setValue,
        formState: { errors },
        watch,
      } = formProps

      register("collaborators")
      register("collaborator_names")

      const value = watch("collaborators")
      return (
        <IndividualSelectWithSearch
          labelconfig={{
            label: "Collaborators",
            error: errors?.collaborator_ids?.message || null,
            required: formRules?.collaborator_ids?.required,
          }}
          onChange={e => {
            const values = e?.map(i => i?.value)

            const ids = values?.filter(a => Number.isInteger(a))
            const strings = values?.filter(a => typeof a === "string")

            setValue(
              "collaborators",
              e?.map(item => {
                return { label: item?.label, value: item?.value }
              })
            )
            setValue("collaborator_ids", ids)
            setValue("collaborator_names", strings)
          }}
          value={value?.map(item => {
            return {
              label: item?.label || item?.collaborator_name,
              value:
                item?.value ||
                (item?.profile_id ? item?.profile_id : item?.collaborator_name),
            }
          })}
        />
      )
    },
  })
  const buildDescriptionField = () => ({
    key: "description",
    title: "Description",
    fieldType: "textArea",
    col: {
      xs: 24,
    },
    placeholder: "",
  })
  const buildPostOnFeedFiled = () => ({
    key: "post_on_feed",
    title: "Post on your feed?",
    fieldType: "checkbox",
    col: {
      sm: 24,
      xs: 24,
    },
    placeholder: "",
  })

  const formRules = {
    images: {
      required: "This field is required",
    },
    title: {
      required: "This field is required",
    },
    dimension: {
      required: "This field is required",
    },
    description: {
      required: "This field is required",
    },
    product_category_id: {
      required: "This field is required",
    },
    product_properties_ids: {
      required: "This field is required",
    },
    product_material_ids: {
      required: "This field is required",
    },
    product_service_category_ids: {
      required: "This field is required",
    },
    manufacture_date: {
      required: "This field is required",
    },
  }

  const fields = [
    buildDescriptionField(),
    buildMeasureField(),
    buildAvailableFromField(),
    buildTeamMemberIdsField(),
    buildBuyersField(),
    buildCollaboratorIdsField(),
    buildTargetAudienceField(),
    buildReadMoreLinksField(),
  ]

  if (!forCommunity) {
    fields.push(buildPostOnFeedFiled(initialData))
  }

  return <FormGenerator fields={fields} rules={formRules} {...props} />
}
