import { Button, Tooltip } from "antd"
import { AddPostContext } from "components/Feed/AddPost/AddPostContext"
import { useContext } from "react"

export default function AddPostModalContentFormButtons() {
  const {
    savedPhoto,
    savedVideo,
    savedDocument,
    showPhoto,
    togglePhoto,
    showVideo,
    toggleVideo,
    setSavedVideo,
    showDocument,
    toggleDocument,
  } = useContext(AddPostContext)

  return (
    <>
      <Tooltip placement="bottom" title="Add images">
        <Button
          type="link"
          className="m-0 px-2"
          onClick={() => togglePhoto(!showPhoto)}
          disabled={savedVideo.length || savedDocument.length}
        >
          <span className="icon-ic_photo_library_24px f-23" />
        </Button>
      </Tooltip>
      <Tooltip placement="bottom" title="Add videos">
        <Button
          type="link"
          className="m-0 px-2"
          onClick={() => {
            toggleVideo(!showVideo)
            setSavedVideo([])
          }}
          disabled={savedPhoto.length || savedDocument.length}
        >
          <span className="icon-ic_video_library_24px f-23" />
        </Button>
      </Tooltip>
      <Tooltip placement="bottom" title="Add documents">
        <Button
          type="link"
          className="m-0 px-2"
          onClick={() => toggleDocument(!showDocument)}
          disabled={savedPhoto.length || savedVideo.length}
        >
          <span className="icon-ic_library_books_24px f-23" />
        </Button>
      </Tooltip>
    </>
  )
}
