import ImageGalleryWithCaption from "components/CustomFields/ImageGalleryWithCaption"
import ProductCategoryDropDownField from "components/CustomFields/ProductCategoryDropDownField"
import FormGenerator from "components/FormGenerator"

export const FirstPageForm = props => {
  const { initialData } = props

  const buildImagesField = initialData => ({
    key: "images",
    fieldType: "customSingleField",
    col: {
      xs: 24,
    },
    render: formProps => {
      const {
        register,
        setValue,
        formState: { errors },
      } = formProps
      register("images")
      register("captions")
      register("cover_index")

      return (
        <ImageGalleryWithCaption
          directory="public/product"
          initialData={initialData}
          error={errors?.images?.message}
          onChange={values => {
            setValue(
              "cover_index",
              values?.images?.findIndex(item => item.is_cover)
            )
            setValue(
              "captions",
              values?.images?.map(item => item.caption)
            )
            setValue(
              "images",
              values?.images?.map(item => item?.response?.data || item?.url)
            )
          }}
        />
      )
    },
  })
  const buildTitleField = () => ({
    key: "title",
    title: "Product Title",
    fieldType: "input",
    col: {
      sm: 12,
      xs: 24,
    },
    placeholder: "Ex: Copenhagen New Library",
  })
  const buildProductCategoryIdField = () => ({
    key: "product_category_id",
    title: "Product Category",
    fieldType: "customSingleField",
    col: {
      sm: 12,
      xs: 24,
    },
    placeholder: "Select Product Type",
    render: formProps => {
      const {
        setValue,
        formState: { errors },
        watch,
      } = formProps
      const value = watch("product_category_id")
      return (
        <ProductCategoryDropDownField
          labelconfig={{
            label: "Product Category",
            error: errors?.product_category_id?.message || null,
            required: formRules?.product_category_id?.required,
          }}
          onChange={(val, e) => {
            setValue("product_category_id", { title: e.label, value: val })
          }}
          value={value}
        />
      )
    },
  })
  const formRules = {
    images: {
      required: "This field is required",
    },
    title: {
      required: "This field is required",
    },
    dimension: {
      required: "This field is required",
    },
    description: {
      required: "This field is required",
    },
    product_category_id: {
      required: "This field is required",
    },
    product_properties_ids: {
      required: "This field is required",
    },
    product_material_ids: {
      required: "This field is required",
    },
    product_service_category_ids: {
      required: "This field is required",
    },
    manufacture_date: {
      required: "This field is required",
    },
  }

  const product_category = initialData?.product_category
  const adaptedInitialData = JSON.parse(JSON.stringify(props.initialData))

  if (product_category) {
    adaptedInitialData.product_category_id = {
      title: product_category?.title,
      value: product_category?.id,
    }
  }
  const fields = [
    buildImagesField(adaptedInitialData),
    buildTitleField(adaptedInitialData),
    buildProductCategoryIdField(adaptedInitialData),
  ]
  return (
    <FormGenerator
      fields={fields}
      rules={formRules}
      {...props}
      initialData={adaptedInitialData}
    />
  )
}
