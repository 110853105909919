import { PhysicalFacilityForm } from "components/Profile/editModals/AddProductModalContent/Forms/PhysicalFacilityForm"
import { PhysicalProductForm } from "components/Profile/editModals/AddProductModalContent/Forms/PhysicalProductForm"
import { ServiceForm } from "components/Profile/editModals/AddProductModalContent/Forms/ServiceForm"
import { SoftwareServicesForm } from "components/Profile/editModals/AddProductModalContent/Forms/SoftwareServicesForm"

export default function SecondPage({ type, ...props }) {
  return (
    <div>
      {type === "softwareServicesForm" ? (
        <SoftwareServicesForm {...props} />
      ) : type === "physicalProductForm" ? (
        <PhysicalProductForm {...props} />
      ) : type === "physicalFacilityForm" ? (
        <PhysicalFacilityForm {...props} />
      ) : (
        <ServiceForm {...props} />
      )}
    </div>
  )
}
