import { CloseOutlined } from "@ant-design/icons"
import { Tag } from "antd"
import CPCascader from "components/CP/CPCascader"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getServiceServiceAreasList,
  getSoftwareServiceAreasList,
  serviceAreasReduxState,
} from "states/slices/serviceAreasSlice"

function ServiceAreasDropdownField({
  type,
  handleRemove,
  addNewItem = true,
  ...props
}) {
  const dispatch = useDispatch()
  const dataInRedux = useSelector(serviceAreasReduxState)
  useEffect(() => {
    if (!dataInRedux?.data?.[type]) {
      if (type === "service") {
        dispatch(getServiceServiceAreasList())
      } else if (type === "software") {
        dispatch(getSoftwareServiceAreasList())
      }
    }
  }, [type])

  const renderSelectedValues = () => {
    return props?.selectedValues?.map((item, index) => {
      const found = dataInRedux?.data?.[type]
        ?.flatMap(cg => [cg, ...(cg.children ? cg.children : [])])
        ?.find(c => c.id === item.id)
      return (
        <Tag
          key="Tag"
          closable={handleRemove}
          closeIcon={<CloseOutlined />}
          onClose={e => {
            e.preventDefault()
            handleRemove(item, index)
          }}
          className="text-xs rounded-lg py-1 border-gray-300 mb-1 mr-1 inline-flex items-center"
        >
          {found?.title || item.title}
        </Tag>
      )
    })
  }

  return (
    <>
      <CPCascader
        {...props}
        search={addNewItem}
        className="w-full"
        disabled={dataInRedux?.loading}
        loading={dataInRedux?.loading}
        data={dataInRedux?.data?.[type]?.map(item => {
          return {
            label: item?.title,
            value: item?.id,
            children: item?.children?.map(subItem => ({
              label: subItem?.title,
              value: subItem?.id,
            })),
          }
        })}
      >
        {props?.children || null}
      </CPCascader>

      {props?.selectedValues && (
        <div className="mt-2">{renderSelectedValues()}</div>
      )}
    </>
  )
}

export default ServiceAreasDropdownField
