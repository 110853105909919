import { AddPostContext } from "components/Feed/AddPost/AddPostContext"
import { useContext } from "react"

const AddPostModalTitle = () => {
  const {
    editMode,
    showPhoto,
    showVideo,
    showCategory,
    showSpaces,
    showDocument,
    showVisibility,
    referencePost,
    selectedSpace,
  } = useContext(AddPostContext)

  return (
    (editMode && "Edit Post") ||
    (showPhoto && "Add Photos") ||
    (showVideo && "Add Videos") ||
    (showCategory && "Choose Post Type") ||
    (showSpaces && "Choose a Space") ||
    (showDocument && "Add Documents") ||
    (showVisibility && "Who can see your post?") ||
    (referencePost && "Share a Post") ||
    (selectedSpace && "Create a Quick Post") ||
    "Create a Post"
  )
}

export default AddPostModalTitle
