import { Button, Dropdown, Image, Modal } from "antd"
import { AppContext } from "components/AppContext"
import AbuseReport from "components/Feed/Posts/AbuseReport"
import { useSubscriptionContext } from "components/Subscription/SubscriptionProvider"
import { useRouter } from "next/router"
import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import apiService from "services/apiService"
import { notifyUser } from "services/notifyToast"
import { setReference } from "states/slices/addPostSlice"
import { communityReduxState } from "states/slices/communitySlice"
import { companyReduxState } from "states/slices/companySlice"
import { setData } from "states/slices/feedSlice"
import { profileState } from "states/slices/profileReducer"

const { confirm } = Modal

const ActionsMenu = ({
  item,
  onHide,
  onPin,
  reference,
  refreshData,
  hideFollow = false,
  toggleEditModal,
  hideRemoveBookmark,
} = {}) => {
  const { checkSubscription } = useSubscriptionContext()
  const { query } = useRouter()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [instance, setInstance] = useState(null)
  const [showAbuseReport, toggleAbuseReport] = useState(false)
  const { userInfo } = useSelector(profileState)
  const isMe =
    userInfo?.profile_id === (item.creator_profile_id || item.profile_id)

  useEffect(() => {
    if (item) {
      setInstance(item)
    }
  }, [item])

  const { userPolicies: companyPolicies } = useSelector(companyReduxState)
  const { userPolicies: communityPolicies } = useSelector(communityReduxState)
  const { communityDetails = null, companyDetails = null } =
    useContext(AppContext) || {}

  const findCompany =
    companyDetails ||
    (item.company_profile_id &&
      userInfo?.companies?.find(
        co => co?.company_profile_id === item.company_profile_id
      ))

  const companyId = findCompany
    ? findCompany?.profile_id || item.profile_id
    : null
  const isAdmin = userInfo?.companies?.find(
    item => item.username === query?.username
  )

  let removePermission = isMe || !!findCompany
  let updatePermission = isMe
  const pinPermission =
    communityDetails && communityDetails.membership_scope === "super_admin"

  if (!removePermission) {
    if (communityDetails && !["general"].includes(item.privacy)) {
      removePermission = communityPolicies?.some(policy =>
        ["delete_post"].includes(policy)
      )
    } else if (companyDetails) {
      removePermission =
        isAdmin &&
        companyPolicies?.some(policy => ["delete_post"].includes(policy))
      updatePermission =
        isAdmin &&
        companyPolicies?.some(policy => ["update_post"].includes(policy))
    }
  }

  const showFunctions = isAdmin || removePermission || updatePermission
  const handleUnFollow = async () => {
    try {
      setLoading(true)
      await apiService.delete(`/api/network/connection/${item.connection_id}`)
      if (refreshData) {
        refreshData()
      }
    } catch (e) {
      checkSubscription(e)
    }

    setLoading(false)
    if (onHide) {
      onHide(true)
    }
  }

  const handlePinAction = async () => {
    try {
      setLoading(true)
      const { data } = await apiService.put(
        `/api/network/post/${item.id}/${item.is_pinned ? "unpin" : "pin"}`
      )

      if (onPin) {
        onPin(data.data.is_pinned)
      }

      notifyUser({
        type: "success",
        description: "This post pinned successfully",
      })
      setLoading(false)
    } catch (e) {
      setLoading(false)
      checkSubscription(e)
    }
  }

  const handleRemove = async () => {
    try {
      setLoading(true)
      await apiService.delete(
        `/api/network${companyId ? `/company/${companyId}` : ""}/post/${
          item.id
        }`
      )

      notifyUser({
        type: "success",
        description: "This post removed successfully",
      })
      setLoading(false)
      if (onHide) {
        onHide(true)
      }
    } catch (e) {
      setLoading(false)
      checkSubscription(e)
    }
  }

  const handleAddBookmark = async () => {
    try {
      setLoading(true)
      await apiService.post("/api/network/bookmark/", { post_id: item.id })

      if (instance) {
        setInstance(prev => ({ ...prev, is_bookmarked: true }))
      }
    } catch (e) {
      checkSubscription(e)
    }

    setLoading(false)
  }

  const handleRemoveBookmark = async () => {
    try {
      setLoading(true)
      await apiService.delete(`/api/network/bookmark/${item.id}`)

      if (instance) {
        setInstance(prev => ({ ...prev, is_bookmarked: false }))
      }
      if (hideRemoveBookmark) {
        notifyUser({
          type: "success",
          description: "This post removed from bookmark",
        })

        if (onHide) {
          onHide(true)
        }
      }
    } catch (e) {
      checkSubscription(e)
    }

    setLoading(false)
  }

  const style = { padding: 0 }
  const actionMenu = showFunctions
    ? [
        pinPermission
          ? {
              style,
              key: "pin-post",
              label: (
                <Button
                  block
                  type="link"
                  disabled={loading}
                  className="px-1 flex justify-start items-center"
                  onClick={handlePinAction}
                >
                  <span
                    style={{ minWidth: 25 }}
                    className="mr-2 flex justify-center"
                  >
                    <Image
                      alt="pin"
                      width={18}
                      height={18}
                      preview={false}
                      src="/assets/icons/thumbtack-solid.svg"
                    />
                  </span>
                  {item.is_pinned ? "Unpin" : "Pin"} Post
                </Button>
              ),
            }
          : null,
        updatePermission
          ? {
              style,
              key: "edit-post",
              label: (
                <Button
                  block
                  type="link"
                  disabled={loading}
                  className="px-1 flex justify-start items-center"
                  onClick={() => {
                    dispatch(setData(item))
                    if (reference) {
                      dispatch(setReference(reference))
                    }
                    if (toggleEditModal) {
                      toggleEditModal(true)
                    }
                  }}
                >
                  <span
                    style={{ minWidth: 25 }}
                    className="icon-ic_edit_24px mr-2 f-18"
                  />
                  Edit Post
                </Button>
              ),
            }
          : null,
        removePermission
          ? {
              style,
              key: "delete-post",
              label: (
                <Button
                  block
                  type="link"
                  disabled={loading}
                  className="px-1 flex justify-start items-center"
                  onClick={() => {
                    confirm({
                      styles: {
                        body: {
                          padding: 15,
                        },
                      },
                      centered: true,
                      title: "Delete a post",
                      content: "Are you sure you want to delete this post?",
                      okText: "Yes",
                      okType: "danger",
                      cancelText: "No",
                      onOk: handleRemove,
                    })
                  }}
                >
                  <span
                    style={{ minWidth: 25 }}
                    className="icon-ic_delete_24px mr-2 f-18"
                  />
                  Delete Post
                </Button>
              ),
            }
          : null,
      ]
    : [
        (companyDetails ? companyDetails.is_following : !hideFollow)
          ? {
              style,
              key: "unfollow",
              label: (
                <Button
                  block
                  type="link"
                  disabled={loading}
                  className="px-1 flex justify-start items-center"
                  onClick={handleUnFollow}
                >
                  <span
                    style={{ minWidth: 25 }}
                    className="icon-ic_cancel_24px mr-2 f-18"
                  />
                  Unfollow {item.full_name?.split(" ")[0]}
                </Button>
              ),
            }
          : null,
      ].filter(list => list)

  if (!userInfo) {
    return null
  }

  return (
    <>
      <Dropdown
        arrow
        menu={{
          items: [
            {
              style,
              key: "bookmark",
              label: (
                <Button
                  block
                  type="link"
                  disabled={loading}
                  className="px-1 flex justify-start items-center"
                  onClick={() =>
                    instance?.is_bookmarked
                      ? handleRemoveBookmark()
                      : handleAddBookmark()
                  }
                >
                  <span
                    style={{ minWidth: 25 }}
                    className={`${
                      instance?.is_bookmarked
                        ? "icon-ic_bookmark_24px"
                        : "icon-ic_bookmark_border_24px"
                    } mr-2 f-18`}
                  />
                  {instance?.is_bookmarked ? "Remove from saved" : "Save Post"}
                </Button>
              ),
            },
            ...actionMenu,
            !isMe && !showFunctions
              ? {
                  style,
                  key: "abuse-report",
                  label: (
                    <Button
                      block
                      type="link"
                      disabled={loading}
                      className="px-1 flex justify-start items-center"
                      onClick={() => {
                        toggleAbuseReport(true)
                      }}
                    >
                      <span
                        style={{ minWidth: 25 }}
                        className="icon-ic_flag_24px mr-2 f-18"
                      />
                      Report Post
                    </Button>
                  ),
                }
              : null,
          ].filter(list => list),
        }}
        trigger={["click"]}
        placement="bottomRight"
        className="p-0"
      >
        <Button
          type="link"
          loading={loading}
          style={{ width: 35, height: 35 }}
          className="px-1 flex items-center justify-center"
        >
          {!loading && <span className="icon-ic_more_horiz_24px f-4" />}
        </Button>
      </Dropdown>

      <AbuseReport
        report_id={instance?.id}
        report_type="post"
        showModal={showAbuseReport}
        closeModal={() => {
          toggleAbuseReport(false)
        }}
      />
    </>
  )
}

export default ActionsMenu
