import { CPModal } from "components/CP/CPModal"
import { PostProvider } from "components/Feed/AddPost/AddPostContext"
import AddPostModalContent from "components/Feed/AddPost/AddPostModalContent"
import AddPostModalTitle from "components/Feed/AddPost/AddPostModalTitle"
import { useDispatch, useSelector } from "react-redux"
import { toggleDialog } from "states/slices/addPostSlice"

const AddPostModal = ({
  url,
  onFetch,
  onUpdate,
  toggleModal,
  showModal = false,
  disablePrivacy = false,
}) => {
  const dispatch = useDispatch()
  const { editMode } = useSelector(state => state.feed)
  const { showPanel, referencePost } = useSelector(state => state.addPost)

  return (
    <PostProvider
      editMode={editMode}
      referencePost={referencePost}
      url={url}
      onFetch={onFetch}
      onUpdate={onUpdate}
      disablePrivacy={disablePrivacy}
      toggleModal={toggleModal}
    >
      <CPModal
        centered
        footer={false}
        destroyOnClose
        open={showPanel || showModal}
        maskClosable={false}
        title={<AddPostModalTitle />}
        onCancel={() => {
          if (toggleModal) {
            toggleModal(false)
          }
          dispatch(toggleDialog(false))
        }}
      >
        <AddPostModalContent />
      </CPModal>
    </PostProvider>
  )
}

export default AddPostModal
