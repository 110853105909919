import { LinkOutlined } from "@ant-design/icons"
import { Avatar, Card, Space, Typography } from "antd"
import CPButton from "components/CP/CPButton"
import Cover from "components/CustomFields/Cover"
import ConnectionStatus from "components/Profile/ConnectionStatus"
import Link from "next/link"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import apiService from "services/apiService"
import { notifyToast } from "services/notifyToast"
import { profileState } from "states/slices/profileReducer"
import { truncate } from "../../../utils/global"

const { Text, Paragraph } = Typography

export default function FastAccessMenu(props) {
  const { item } = props
  const [items, setItems] = useState([])
  const { userInfo } = useSelector(profileState)
  const isMe = item?.username === userInfo?.username

  const fetchMutualFriends = async () => {
    try {
      const res = await apiService.get(
        `/api/network/connection/profile/${item?.profile_id}/mutual_friends`
      )
      setItems(res?.data?.data)
    } catch (e) {
      await notifyToast(e)
    }
  }

  useEffect(() => {
    if (item?.profile_id && !isMe) {
      fetchMutualFriends()
    }
  }, [item?.profile_id])

  const link = item?.username ? `/profile/${item?.username}` : ""

  return (
    <Card
      styles={{ body: { padding: 16 } }}
      style={{
        width: 270,
      }}
      cover={
        <Cover
          allowOverflow={true}
          className="flex items-end justify-center rounded-t-lg"
          img={item?.profile_cover_photo}
        >
          <Link passHref prefetch={false} href={link || ""} key="Avatar">
            <Avatar
              size={96}
              alt={item?.name}
              src={
                item?.profile_photo || "/assets/onBoarding/profile_avatar.jpg"
              }
              className="bg-gray-100 relative cursor-pointer"
              style={{
                bottom: -20,
                boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.2)",
              }}
            />
          </Link>
        </Cover>
      }
      onClick={event => event.preventDefault()}
    >
      <div className="flex flex-col">
        <Paragraph
          ellipsis={{ rows: 3, expandable: true, symbol: "more" }}
          className="f-18 font-medium m-0"
        >
          {item?.full_name}
        </Paragraph>
        <Link
          passHref
          prefetch={false}
          href={link || ""}
          key="headline"
          className="truncate"
        >
          <Text className="f-15 cursor-pointer" type="primary">
            {truncate(item?.headline, { length: 50 })}
          </Text>
        </Link>

        {items?.length !== 0 && (
          <Space className="mt-3" size={5}>
            <LinkOutlined rotate={-45} className="f-22" />

            <div className="flex flex-col">
              <Text className="f-12" type="secondary" strong>
                {items.length} mutual connections
              </Text>
              <Text className="f-12" type="secondary">
                {items.map(
                  (friend, index) =>
                    index < 2 && (
                      <Link
                        passHref
                        prefetch={false}
                        href={
                          friend?.username ? `/profile/${friend?.username}` : ""
                        }
                        key={index}
                      >
                        <span className="mr-2 cursor-pointer hover:text-primary">
                          {friend.first_name} {friend.last_name}
                        </span>
                      </Link>
                    )
                )}
              </Text>
            </div>
          </Space>
        )}
      </div>

      {isMe ? (
        <Link passHref prefetch={false} href={`/profile/${userInfo?.username}`}>
          <CPButton type="primary" block className="mt-4 py-2 text-black">
            View Profile
          </CPButton>
        </Link>
      ) : (
        <ConnectionStatus className="mt-4" item={item} />
      )}
    </Card>
  )
}
