import { Button, Carousel, Image, Row, Upload } from "antd"
import DocumentGallery from "components/CustomFields/DocumentGallery"
import ImageGallery from "components/CustomFields/ImageGallery"
import VideoGallery from "components/CustomFields/VideoGallery"
import { AddPostContext } from "components/Feed/AddPost/AddPostContext"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { notifyUser } from "services/notifyToast"
import { guid } from "utils/global"
import { getItem } from "utils/storage"

const { PreviewGroup } = Image

export default function AddAssetModal() {
  const {
    savedPhoto,
    setSavedPhoto,
    savedVideo,
    setSavedVideo,
    savedDocument,
    setSavedDocument,
    showPhoto,
    togglePhoto,
    showVideo,
    toggleVideo,
    showDocument,
    toggleDocument,
  } = useContext(AddPostContext)

  const { Dragger } = Upload
  const accessToken = getItem("access_token")
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL

  const carousel = useRef(null)
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)

  const options = useMemo(() => {
    if (showPhoto) {
      return {
        max: 10,
        type: "photo",
        replace: "+ Add more photos",
        accept: "image/png,image/jpg,image/jpeg,image/gif",
        placeholder: "Drag maximum 10 photos here or",
        hint: "Upload .jpg, .gif, or .png images up to 5MB each.",
      }
    }
    if (showVideo) {
      return {
        max: 1,
        type: "video",
        accept: "video/*",
        replace: "Change Video",
        placeholder: "Drag a video here or",
        hint: "Upload .mp4 and .mov Video up to 100MB.",
      }
    }
    if (showDocument) {
      return {
        max: 1,
        type: "document",
        accept: ".pdf",
        replace: "Change Document",
        placeholder: "Drag a document here or",
        hint: "Upload .pdf Document up to 25MB.",
      }
    }
  }, [showPhoto, showVideo, showDocument])

  const data = useMemo(() => {
    if (showPhoto) {
      return savedPhoto
    }
    if (showVideo) {
      return savedVideo
    }
    if (showDocument) {
      return savedDocument
    }
  }, [
    showPhoto,
    showVideo,
    showDocument,
    savedPhoto,
    savedVideo,
    savedDocument,
  ])

  const setData = input => {
    if (showPhoto) {
      setSavedPhoto(input)
    }
    if (showVideo) {
      setSavedVideo(input)
    }
    if (showDocument) {
      setSavedDocument(input)
    }
  }

  const onBack = () => {
    togglePhoto(false)
    toggleVideo(false)
    toggleDocument(false)
  }

  useEffect(() => {
    if (!data) {
      return
    }
    const assetFiles = data
    if (assetFiles) {
      setFiles(assetFiles.map(item => ({ ...item, uid: guid() })))
    }
  }, [data])

  const onRemove = file => {
    setFiles(prevFiles => prevFiles.filter(item => item.uid !== file.uid))
  }

  const onChange = ({ file }) => {
    if (!file) {
      return
    }

    const { status } = file
    if (status === "done") {
      const mFile = {
        uid: guid(),
        title: file.name,
        file: URL.createObjectURL(file.originFileObj),
        response: file.response.data,
      }
      setFiles(prevFiles => [mFile, ...prevFiles].slice(0, options?.max))
      setLoading(false)
    } else if (status === "error") {
      notifyUser({ title: `${file.name} file upload failed.` })
      setLoading(false)
    }
  }

  const showMarkup = useMemo(
    () =>
      files?.length !== 0 &&
      (options?.max > 1 ? files?.length < options?.max : false),
    [files, options]
  )

  const container = useMemo(() => {
    const renderGallery = Component =>
      files?.map((item, index) => (
        <div className="relative" key={index}>
          <Component item={item} onRemove={() => onRemove(item)} />
        </div>
      ))

    switch (options?.type) {
      case "photo":
        return files?.map((item, index) => (
          <Row gutter={[0, 0]} className="relative" key={index} align="center">
            <ImageGallery
              src={item.file || item.url}
              getFile={!!item.url}
              fileLength={1}
              onRemove={() => onRemove(item)}
            />
          </Row>
        ))
      case "document":
        return renderGallery(DocumentGallery)
      case "video":
        return renderGallery(VideoGallery)
      default:
        return null
    }
  }, [files, options])

  const draggerMarkup = (
    <>
      <Dragger
        className="mb-4"
        multiple
        maxCount={options?.max}
        name="file"
        disabled={loading}
        action={`${baseUrl}/api/files/`}
        accept={options?.accept}
        beforeUpload={() => {
          setLoading(true)
          return true
        }}
        showUploadList={false}
        headers={{
          authorization: `Bearer ${accessToken}`,
        }}
        progress={{
          strokeWidth: 3,
          format: percent => `${parseInt(percent.toFixed(2))}%`,
        }}
        onChange={onChange}
      >
        <div className="f-15 text-center text-gray-500 m-0 font-bold">
          {options?.placeholder}
          <span className="text-primary px-1">browse</span>
          to upload
        </div>
      </Dragger>
      <div className="text-center text-gray-500 f-14 mt-4">{options?.hint}</div>
    </>
  )

  return (
    <div className="p-5">
      {files?.length ? (
        <PreviewGroup>
          <Carousel
            dots
            dotPosition="bottom"
            arrows={files?.length > 1}
            className="overflow-hidden relative"
            ref={carousel}
            infinite={false}
          >
            {container}
          </Carousel>
        </PreviewGroup>
      ) : (
        <div>{draggerMarkup}</div>
      )}

      <div className="pt-6">{showMarkup && draggerMarkup}</div>

      <div className="pt-6 flex items-center">
        <div className="flex-grow pl-3" />
        <Button
          type="default"
          className="px-8"
          disabled={loading}
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          type="primary"
          className="px-8"
          htmlType="submit"
          disabled={loading}
          loading={loading}
          onClick={() => {
            setData(files)
            onBack()
          }}
        >
          Save
        </Button>
      </div>
    </div>
  )
}
