import { AppContext } from "components/AppContext"
import { createContext, useContext, useEffect, useState } from "react"

export const Categories = [
  {
    title: "Quick Post",
    value: "quick_post",
    description: "Anyone can have access to your post",
  },
  {
    title: "Portfolio+",
    value: "portfolio",
    showMore: "icon-ic_keyboard_arrow_right_24px f-13 ml-3",
    description: "Add project to your portfolio and share it with others",
  },
]

export const Visibilities = [
  {
    icon: "icon-ic_public_24px",
    name: "Public",
    value: "public",
    description: "Anyone can have access to your post",
  },
  {
    icon: "icon-ic_people_24px",
    name: "Connections",
    value: "connections",
    description: "Your connections on Birdflocks",
  },
  {
    icon: "icon-ic_community_24px",
    name: "Community Members",
    value: "community",
    showMore: "icon-ic_keyboard_arrow_right_24px f-13 ml-3",
    description: "Members within following communities in Birdflocks",
  },
]

export const AddPostContext = createContext()

export const PostProvider = ({
  children,
  editMode,
  referencePost,
  url,
  onFetch,
  onUpdate,
  disablePrivacy,
  toggleModal,
}) => {
  const { companyDetails = null, communityDetails = null } =
    useContext(AppContext) || {}

  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [savedPhoto, setSavedPhoto] = useState([])
  const [savedVideo, setSavedVideo] = useState([])
  const [savedDocument, setSavedDocument] = useState([])
  const [category, setCategory] = useState(Categories[0])
  const [visibility, setVisibility] = useState(Visibilities[0])
  const [communityType, setCommunityType] = useState(null)
  const [portfolioType, setPortfolioType] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showPhoto, togglePhoto] = useState(false)
  const [showVideo, toggleVideo] = useState(false)
  const [showDocument, toggleDocument] = useState(false)
  const [showCategory, toggleCategory] = useState(false)
  const [showSpaces, toggleSpaces] = useState(false)
  const [showVisibility, toggleVisibility] = useState(false)

  const companyId =
    companyDetails?.profile_id || referencePost?.companyId || null
  const communityId =
    communityType?.id || editMode?.community_id || communityDetails?.id

  useEffect(() => {
    if (!editMode) {
      return
    }

    if (editMode.title) {
      setTitle(editMode.title)
    }

    if (editMode.body) {
      setDescription(
        editMode.body.replace(/<a\b[^>]*>/gi, "").replace(/<\/a>/gi, "")
      )
    }

    if (editMode.files?.length) {
      const photos = editMode.files.filter(file =>
        file.type.startsWith("image")
      )
      const videos = editMode.files.filter(file =>
        file.type.startsWith("video")
      )
      const documents = editMode.files.filter(file => file.type.endsWith("pdf"))

      setSavedPhoto(photos)
      setSavedVideo(videos)
      setSavedDocument(documents)
    }
  }, [editMode])

  return (
    <AddPostContext.Provider
      value={{
        url,
        onFetch,
        onUpdate,
        disablePrivacy,
        title,
        setTitle,
        description,
        setDescription,
        savedPhoto,
        setSavedPhoto,
        savedVideo,
        setSavedVideo,
        savedDocument,
        setSavedDocument,
        category,
        setCategory,
        visibility,
        setVisibility,
        communityType,
        setCommunityType,
        portfolioType,
        setPortfolioType,
        loading,
        setLoading,
        showPhoto,
        togglePhoto,
        showVideo,
        toggleVideo,
        showDocument,
        toggleDocument,
        showCategory,
        toggleCategory,
        showSpaces,
        toggleSpaces,
        showVisibility,
        toggleVisibility,
        editMode,
        referencePost,
        toggleModal,
        companyId,
        communityId,
      }}
    >
      {children}
    </AddPostContext.Provider>
  )
}
