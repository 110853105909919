import { Button, Col, Image, Spin } from "antd"
import { useEffect, useState } from "react"
import apiService from "services/apiService"
import { shapeImages } from "utils/global"

export default function ImageGallery({
  src,
  solid = false,
  index = 0,
  fileLength = 0,
  onGetImage,
  onRemove,
  getFile = false,
} = {}) {
  const [item, setItem] = useState(null)
  const [loading, setLoading] = useState(false)
  const [cleanUp, setCleanUp] = useState(false)

  const fetchData = async () => {
    if (cleanUp) {
      return
    }
    if (!src) {
      return setItem(null)
    }

    try {
      setLoading(true)
      const res = await apiService.get(`/api/files/?url=${src}`, {
        responseType: "blob",
      })
      const url = window.URL.createObjectURL(res.data)
      if (onGetImage) {
        onGetImage({ file: url, index })
      }

      setItem(url)
    } catch (e) {
      console.log(e)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (!getFile || /http|https/.test(src)) {
      return setItem(null)
    }

    fetchData()
    return () => setCleanUp(true)
  }, [])

  if (solid) {
    return <Image preview={false} alt="" src={item} />
  }

  const imageSize = shapeImages(index, fileLength)
  return (
    <Col xs={imageSize}>
      {loading ? (
        <div
          style={{
            height: imageSize === 24 ? 500 : imageSize === 12 ? 230 : 150,
          }}
        >
          <Spin className="m-3" />
        </div>
      ) : (
        <div className="relative">
          {onRemove && (
            <Button
              style={{ height: 38, width: 38 }}
              className={
                "border-0 rounded-3xl shadow flex items-center justify-center bg-white cursor-pointer absolute z-10 top-0 end-0 m-2"
              }
              onClick={onRemove}
            >
              <span className="icon-ic_delete_24px d-inline-block f-17" />
            </Button>
          )}
          <Image
            alt=""
            className="rounded-lg overflow-hidden bg-white border border-gray-300 gallery-image"
            width="100%"
            src={item ?? src}
            height={imageSize === 24 ? undefined : imageSize === 12 ? 230 : 150}
          />
        </div>
      )}
    </Col>
  )
}
