import { Button, Divider } from "antd"
import { CPModal } from "components/CP/CPModal"
import PreviewContent from "components/Profile/editModals/PreviewContent"

export default function ModalPreview({
  data,
  hideModal,
  showModal,
  fields,
  type,
}) {
  return (
    <CPModal
      width={800}
      footer={null}
      onCancel={hideModal}
      title={data?.title || "Project Details"}
      open={showModal}
      centered
      className="rounded-lg pb-5"
      style={{ top: 20 }}
      maskClosable={false}
    >
      <div className="p-5">
        {data?.images?.length > 0 ? (
          <PreviewContent data={data} type={type} fields={fields} />
        ) : (
          <div style={{ minHeight: "400px" }}>There is no image here</div>
        )}

        <Divider className="border-gray-300 mb-3" />
        <div className="flex justify-end">
          <Button className="px-8" onClick={hideModal}>
            Close
          </Button>
        </div>
      </div>
    </CPModal>
  )
}
