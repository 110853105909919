import { Button, Divider } from "antd"
import { CPModal } from "components/CP/CPModal"
import CPTextArea from "components/CP/CPTextArea"
import { useSubscriptionContext } from "components/Subscription/SubscriptionProvider"
import { useState } from "react"
import apiService from "services/apiService"
import { notifyUser } from "services/notifyToast"

export default function AbuseReport({
  showModal,
  closeModal,
  report_type,
  report_id,
}) {
  const { checkSubscription } = useSubscriptionContext()
  const [loading, setLoading] = useState(false)
  const [body, setBody] = useState("")

  const onSubmit = async () => {
    if (!body.trim()) {
      return notifyUser({
        type: "error",
        description: "Please tell us why there is something wrong here!",
      })
    }

    try {
      const content = {
        report_id,
        report_type,
        body,
      }

      setLoading(true)
      const res = await apiService.post("/api/report/", content)
      closeModal()
      setBody("")

      notifyUser({
        type: "success",
        description:
          res?.data?.messages || "Your report submitted successfully!",
      })
    } catch (e) {
      checkSubscription(e)
    }

    setLoading(false)
  }

  const stopPropagate = e => {
    if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(e.key)) {
      e.stopPropagation()
    }
  }

  return (
    <CPModal
      centered
      footer={false}
      destroyOnClose
      open={showModal}
      onCancel={() => {
        if (!loading) {
          closeModal()
        }
      }}
      title="Abuse Report"
      className="rounded-lg p-0"
    >
      <div className="p-5">
        <div className="px-1 mb-3">
          Are you sure you want to report this {report_type}? Please tell us
          more about it.
        </div>

        <CPTextArea
          disabled={loading}
          className="mb-5"
          placeholder="Write some note"
          value={body}
          onChange={e => setBody(e?.target?.value)}
          onKeyDown={stopPropagate}
        />

        <Divider className="border-gray-300 border-t-1 m-0" />

        <div className="pt-4 flex justify-end">
          <Button
            type="light"
            className="px-8"
            disabled={loading}
            onClick={() => {
              if (!loading) {
                closeModal()
              }
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            loading={loading}
            className="px-8"
            disabled={loading}
            onClick={onSubmit}
          >
            Yes
          </Button>
        </div>
      </div>
    </CPModal>
  )
}
