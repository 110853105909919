import { Button, Card, Skeleton } from "antd"
import AddComment from "components/Feed/Comments/AddComment"
import CommentCard from "components/Feed/Comments/CommentCard"
import { useEffect, useState } from "react"
import apiService from "services/apiService"
import { notifyToast } from "services/notifyToast"

const CommentChildList = ({
  instance,
  postId,
  onRemove,
  companyId,
  setInstance,
} = {}) => {
  const [page, setPage] = useState(1)
  const [items, setItems] = useState([])
  const [newComments, setNewComment] = useState([])

  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState(null)
  const isLastPage = pagination?.current_page >= pagination?.last_page

  const fetchData = async () => {
    try {
      setLoading(true)
      const res = await apiService.get(
        `/api/network/post/${postId}/comment/${instance?.id}?page=${page}`
      )

      if (page > 1) {
        setItems(prev => [...prev, ...res.data.data])
      } else {
        setItems(res?.data?.data)
      }

      setNewComment([])
      setInstance({
        ...instance,
        comments: res?.data?.meta?.total || 0,
      })

      setPagination(res?.data?.meta)
    } catch (e) {
      await notifyToast(e)
    }

    setLoading(false)
  }

  const onCommentAdded = newItem => {
    setNewComment([...newComments, newItem])
    setInstance({
      ...instance,
      comments: Number(instance?.comments) + 1,
      has_reference_comments: true,
    })
  }

  const loadMoreData = () => {
    if (!isLastPage) {
      setPage(page + 1)
    }
  }

  /**
   * fetch
   */
  useEffect(() => {
    if (instance?.has_reference_comments) {
      fetchData()
    }
  }, [page])

  return (
    <div>
      {items?.length !== 0 && (
        <div>
          {items?.map((comment, index) => (
            <CommentCard
              key={index}
              item={comment}
              postId={postId}
              allowReply={false}
              companyId={companyId}
              fetchData={fetchData}
              onRemove={onRemove}
            />
          ))}

          {!loading && !isLastPage && (
            <div className="flex mb-5">
              <Button type="link" className="p-0 f-14" onClick={loadMoreData}>
                Load More
              </Button>
            </div>
          )}
        </div>
      )}

      {newComments?.map((comment, index) => (
        <CommentCard
          mine
          key={index}
          item={comment}
          postId={postId}
          orange={index === newComments?.length - 1}
          companyId={companyId}
          allowReply={false}
          onRemove={() => {
            const prev = [...newComments]
            prev.splice(index, 1)

            setNewComment(prev)
            setInstance({
              ...instance,
              comments: Number(instance?.comments) - 1,
              has_reference_comments:
                instance?.has_reference_comments || newComments.length !== 0,
            })
          }}
        />
      ))}

      {loading && (
        <Card
          className="border-none mb-3"
          styles={{ body: { padding: 12 } }}
          style={{ borderRadius: 15, backgroundColor: "#f9f9f9" }}
        >
          <Skeleton className="small-skeleton" active paragraph={{ rows: 1 }} />
        </Card>
      )}

      <AddComment
        postId={postId}
        className="mb-6"
        companyId={companyId}
        parentId={instance?.id}
        onCommentAdded={onCommentAdded}
      />
    </div>
  )
}

export default CommentChildList
